<template>
    <div class="wrap">
        <div class="Doc-header">
            <div style="background:#f2f2f2">
                <Header :onlyTop='true'></Header>
            </div>

            <div class="Doc-header-logo-wrap">
                <div class="Doc-header-logo">
                    <router-link to='/home/HomePage'>
                    <img src="@/assets/login-logo.png" alt="" style="height:40px">
                    </router-link>
                    <router-link to="/home/HomePage" class="Doc-header-right">返回商城 <i class="el-icon-arrow-right"></i>
                    </router-link>
                </div>
            </div>

            <img src="@/assets/Doc/bg.png" alt="" style="width:100%;vertical-align:middle;">
        </div>

        <div class="main">
            <router-view />
        </div>

        <Foot></Foot>
    </div>
</template>

<script>


    import Nav from './component/Nav'
    import Foot from '@/components/Foot.vue'
    import Header from '@/layout/Header/index.vue'
    export default {
        data() {
            return {
                isLogin: ''
            }
        },
        components: {
            Nav,
            Foot,
            Header
        }
    }
</script>

<style lang="less" scoped>
    @hoverBlue: #188aff;

    .wrap {
        background: rgba(243, 246, 251);
        display: flex;
        flex-direction: column;
        min-height: 600px;
        min-height: 100vh;

        .Doc-header {
            background: #fff;

            .Doc-header-logo-wrap {
                width: 1200px;
                margin: 0 auto;
                .Doc-header-logo {
                    height: 40px;
                    padding: 20px 0px;
                    display: flex;
                    align-items: center;

                    span {
                        margin-left: 20px;
                        font-size: 18px;
                    }

                    .Doc-header-right {
                        color: #a7b1c1;
                        margin-left: auto;
                        font-size: 15px;

                        &:hover {
                            color: @hoverBlue;
                        }
                    }
                }
            }
        }

        .main {
            flex: 1;
            width: 1200px;
            margin: 0 auto;
            min-height: 600px
        }
    }
</style>
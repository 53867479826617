<template>
  <div class="header">
    <div class="header-container" :style="{height:!onlyTop ? '200px' : ''}">
      <div class="top">
        <div>
          <span v-if="!isLogin">你好，请</span>
          <span @click="routerTo(loginTo)" class="hover-color">{{ loginTitle }}</span>
          <span class="line">|</span>
          <router-link :to="registerTo">
            <span class="hover-color" style="color: #606266" @click="switchLoginOut()">{{ register }}</span>
          </router-link>
        </div>
        <ul>
          <li>
            <span class="hover-color" @click="routerTo('/home/HomePage')">商场首页</span>
            <span class="line">|</span>
          </li>
          <li v-if="myStaus == 'BUY'">
            <span class="hover-color" @click="routerTo('/home/SupplierNotice')">供应商入驻</span>
            <span class="line">|</span>
          </li>
          <li>
            <el-dropdown>
              <span @click="myCenter('BUY', '/User/workbench')" class="hover-color el-dropdown-link mouseStyle">买家中心<i class="el-icon-arrow-down el-icon--right"></i> </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item>
                  <span @click="myCenter('BUY', '/User/Publish')">发布竞买</span>
                </el-dropdown-item>
                <el-dropdown-item>
                  <span @click="myCenter('BUY', '/User/ReqOrder', {customer: 'buyder'})">竞买订单</span>
                </el-dropdown-item>
                <el-dropdown-item>
                  <span @click="myCenter('BUY', '/User/MyPublish')">我的竞买</span>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
            <span class="line">|</span>
          </li>
          <li>
            <el-dropdown>
              <span @click="myCenter('SELL', '/User/workbench')" class="hover-color el-dropdown-link mouseStyle">供应商中心<i class="el-icon-arrow-down el-icon--right"></i> </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item>
                  <span @click="myCenter('SELL', '/User/PublishBidding')">发布竞卖</span>
                </el-dropdown-item>
                <el-dropdown-item>
                  <span @click="myCenter('SELL', '/User/ReqOrder', {customer: 'sellder'})">竞卖订单</span>
                </el-dropdown-item>
                <el-dropdown-item>
                  <span @click="myCenter('SELL', '/User/MyBidding')">我的竞卖</span>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
            <span class="line">|</span>
          </li>
          <li>
            <span class="hover-color" @click="$router.push('/DeviceLogin')">服务商中心</span>
            <span class="line">|</span>
          </li>
          <li>
            <span class="hover-color" @click="toFinanceSystem">企业服务中心</span>
            <span class="line">|</span>
          </li>
          <li>客服热线：400-1620-620</li>
        </ul>
      </div>
      <template v-if="!onlyTop">
      <div class="strip">
        <div class="strip-container">
          <img @click="routerTo('/home/HomePage')" src="@/assets/logo2.png" alt="" style="height: 65px" />
          <div class="strip-bigcont">
            <input type="text" class="strip-input" v-model="searchItem" />
            <span @click="search()" class="el-icon-search strip-search"></span>
          </div>
          <div v-if="!isLogin">
            <el-button type="primary" class="btn-register" @click="routerTo('/home/UserRegister')">
              <span style="color: #fff">注册</span>
            </el-button>
            <el-button type="primary" class="btn-login" @click="routerTo('/home/Login')">
              <span style="color: #409eff">登录</span>
            </el-button>
          </div>
        </div>
      </div>
      <div class="tab">
        <div class="tab-main">
          <!-- 产品类目 -->
          <div class="all-cate" @mouseenter="categoriesList = true" @mouseleave="mouseleave">
            <router-link to="/home/Mall">
              <p class="all-cate-tit"><img src="@/assets/nav-logo.png" class="all-cate-tit-img" />产品类目</p>
            </router-link>
            <div class="all-cate-box" v-show="categoriesList">
              <div class="cate-list">
                <div
                  :class="[
                    'cate-item',
                    {
                      isHover: selCateName === item.name
                    }
                  ]"
                  v-for="(item, index) in cateList"
                  :key="item.name"
                  @mouseenter="
                    selCateName = item.name;
                    cateIndex = index;
                  "
                  @click="clickCate(item)"
                >
                  <div class="cate-item-name">
                    <p style="font-size: 14px">
                      {{ item.name }}
                    </p>
                    <i class="el-icon-arrow-right icon"></i>
                  </div>
                </div>
              </div>

              <div class="cate-content" v-show="selCateName !== ''">
                <div class="child-box" v-for="childItem in cateList[cateIndex].child" :key="childItem.name">
                  <p class="child-name" @click="clickCate(childItem)">
                    {{ childItem.name }}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div v-for="item in nav" :key="item.path" :class="['header-nav', selected == item.name ? 'selected' : '']" @click="goTo(item.path, item)">
            {{ item.name }}
          </div>
        </div>
      </div>

      <el-drawer custom-class="message-drawer" class="message-drawer" title="消息中心" :visible.sync="isShowMessage" direction="rtl" :before-close="handleCloseMessage" style="height: 100%">
        <div class="message-list" v-infinite-scroll="loadMessage">
          <div class="message-item" :class="{new: item.readStatus == 2}" v-for="item in messageManage.list" :key="item.msgId">
            <div class="title">
              {{ item.msgTitle }}
            </div>
            <div class="content">
              {{ item.msgContent }}
            </div>
            <div class="date">{{ item.sendTime }}</div>
          </div>
        </div>
      </el-drawer>
      </template>
    </div>
  </div>
</template>

<script>
import {loginOut, getCartCount, categoryAll, getMessgeList, readAllMessage, queryEnterpriseAccount} from '../api';
import {mapState} from 'vuex';
import {financeSystemUrl} from '@/api/config';

export default {
  props:{
    onlyTop:{
      type: Boolean,
      default: false,
    }
  },
  computed: {
    ...mapState(['carCount'])
  },
  mounted() {
    // this.myStaus = localStorage.getItem('MenuType');
    this.initialLocalStorage();
    getCartCount().then(res => {
      this.$store.commit('setCarCount', res.data);
    });
  },
  created() {
    this.selected = this.$route.meta.name;
    this.handlerCategoryAll();

    var loginName = localStorage.getItem('loginName');

    if (loginName != null) {
      this.loginTitle = '你好，' + localStorage.getItem('users');
      this.loginTo = '';
      this.register = '退出登录';
    } else {
      this.loginTitle = '登录';
      this.register = '免费注册';
      this.loginTo = '/home/Login';
    }
    // this.queryNewMessage();
  },
  data() {
    return {
      searchItem: '',
      userInfo: null,
      count: 0,
      isShowMessage: false,
      routeMetaName: '', //当前的路由名称
      accountType: true,
      isLogin: Boolean(localStorage.getItem('loginName')),
      loginTo: '/home/Login',
      loginTitle: '登录',
      register: '免费注册',
      registerTo: '',
      number: 3,
      name: '登陆',
      value: '',
      nav: [
        {
          path: '/home/HomePage',
          name: '首页',
          routeMetaName: '首页详情'
        },
        {
          path: '/home/LoogFor',
          name: '竞买中心',
          routeMetaName: '竞买详情'
        },
        {
          path: '/home/Bidding',
          name: '竞卖中心',
          routeMetaName: '竞卖详情'
        },
        {
          path: '/home/Tender',
          name: '竞标中心',
          routeMetaName: '竞标详情'
        }
      ],
      selected: '',
      cateIndex: 0,
      myStaus: localStorage.getItem('accountType'),
      categoriesList: false,
      selCateName: '',
      cateList: [
        {
          name: '一级类目1',
          child: [
            {
              name: '二级类目1',
              child: [
                {
                  name: '三级类目1'
                },
                {
                  name: '三级类目2'
                },
                {
                  name: '三级类目3'
                },
                {
                  name: '三级类目4'
                }
              ]
            },
            {
              name: '二级类目2',
              child: [
                {
                  name: '三级类目5'
                },
                {
                  name: '三级类目6'
                },
                {
                  name: '三级类目7'
                },
                {
                  name: '三级类目8'
                }
              ]
            }
          ]
        }
      ],
      fixed: '',
      isNewMessage: false,
      messageManage: {
        list: [],
        param: {
          pageSize: 20
        }
      }
    };
  },
  methods: {
    routerTo(path, data) {
      this.$router.push({path, query: data});
    },
    initialLocalStorage() {
      if (!this.getCookie('logined')) {
        localStorage.clear();
        sessionStorage.clear();
      }
    },
    getCookie(name) {
      var nameEQ = name + '=';
      var ca = document.cookie.split(';'); //把cookie分割成组
      for (var i = 0; i < ca.length; i++) {
        var c = ca[i]; //取得字符串
        while (c.charAt(0) == ' ') {
          //判断一下字符串有没有前导空格
          c = c.substring(1, c.length); //有的话，从第二位开始取
        }
        if (c.indexOf(nameEQ) == 0) {
          //如果含有我们要的name
          return unescape(c.substring(nameEQ.length, c.length)); //解码并截取我们要值
        }
      }
      return false;
    },
    goTo(path, data) {
      this.$router.push({path});
      this.selected = data.name;
    },
    myCenter(type, path, data) {
      localStorage.setItem('MenuType', type);
      var _this1 = this;
      if (!_this1.isLogin) {
        _this1.$message.error('请登录再操作');
        this.$router.push({path: '/home/Login'});
        return;
      }
      if (type === 'SELL' && localStorage.getItem('accountType') !== 'SELL') {
        //当用户未成为供应商时，提示不能进行此操作
        this.$message.warning('您还未成为供应商，请先申请成为供应商再进行操作。');
        return;
      }

      let _this = this;
      _this.$store.commit('changeMenuType', type);
      let loginName = localStorage.getItem('loginName');
      if (loginName == null) {
        _this.$message.error('未登录，请登录');
        return;
      }
      _this.$router.push({
        path,
        query: data
      });
    },
    supplierApply() {
      var _this = this;
      var loginName = localStorage.getItem('loginName');
      if (loginName == null) {
        _this.$message.error('未登录，请登录');
        // _this.$router.push({
        //     path: '/home/Login?state=login'
        // });
        return;
      }
      _this.$router.push('/home/SupplierNotice');
    },
    switchLoginOut() {
      var _this = this;
      var loginName = localStorage.getItem('loginName');
      if (loginName != null) {
        localStorage.clear();
        sessionStorage.clear();
        _this.register = '免费注册';
        _this.loginTitle = '登录';
        _this.loginTo = '/home/Login';

        setTimeout(() => {
          _this.$router.push('/home/Login');
        }, 500);
        _this.$message.success('退出成功');

        loginOut().then(res => {});
      } else {
        _this.registerTo = '/home/UserRegister';
      }
    },
    goShopping() {
      var _this1 = this;
      if (!_this1.isLogin) {
        _this1.$message.error('未登录，请登录');
        return;
      }
      this.$router.push('/home/Mall/ShoppingList');
    },
    // 点击分类
    clickCate(item) {
      if (item.id == this.$route.query.cateId) return;
      this.$router.push({
        path: '/home/Mall',
        query: {
          cateId: item.id
        }
      });
    },
    handlerCategoryAll() {
      categoryAll(null).then(res => {
        this.cateList = res.data;
      });
    },
    readAllMessage() {
      readAllMessage({
        userId: this.userInfo.id
      }).then(res => {});
    },
    queryNewMessage() {
      {
        a: we;
      }
      if (!this.isLogin) {
        setTimeout(() => {
          this.queryNewMessage();
        }, 1000);
        return;
      }
      getMessgeList({
        pageSize: 1,
        pageNo: 1,
        receiverUserId: this.userInfo.id
      }).then(res => {
        if (res.errno === 0) {
          this.isNewMessage = res.data.list[0].readStatus == 2;
        }
        setTimeout(() => {
          this.queryNewMessage();
        }, 1000);
      });
    },
    getMessgeList() {
      getMessgeList({
        pageSize: this.messageManage.param.pageSize,
        pageNo: 1,
        receiverUserId: this.userInfo.id
      }).then(res => {
        if (res.errno === 0) {
          this.messageManage.list = res.data.list;
        }
      });
    },
    loadMessage() {
      this.messageManage.param.pageSize += 10;
      this.getMessgeList();
    },
    // 移出一级类目时
    mouseleave() {
      this.categoriesList = false;
      //   if (this.fixed) return (this.categoriesList = true), (this.selCateName = '');
      //   (this.categoriesList = false), (this.selCateName = '');
    },
    handleCloseMessage(done) {
      done();
      this.messageManage.param.pageSize = 20;
      this.readAllMessage();
    },
    showMessage() {
      this.getMessgeList();
      this.isShowMessage = true;
    },
    search() {
      this.$router.push({path: '/home/Mall', query: {searchItem: this.searchItem}});
      this.$store.commit('changeSearchItem', this.searchItem);
    },
    toFinanceSystem() {
      // 查询
      if (this.isLogin) {
        queryEnterpriseAccount().then(res => {
          if (res.errno === 0) {
            if (res.data > 1) {
              window.open(financeSystemUrl);
            } else {
              this.$message('请先添加子账号！');
              this.$router.push({
                path: '/User/SubAccount'
              });
            }
          }
        });
      } else {
        this.$message('请先登录！');
        this.$router.push({
          path: '/home/login',
          query: {
            t: Date.now()
          }
        });
      }
    }
  },
  watch: {
    $route: function(newV, oldV) {
      this.fixed = newV.meta.fixed;
      this.selected = this.$route.meta.name;
      var loginName = localStorage.getItem('loginName');

      if (loginName != null) {
        this.loginTitle = '你好，' + localStorage.getItem('users');
        this.loginTo = '';
        this.register = '退出登录';
      } else {
        this.loginTitle = '登录';
        this.register = '免费注册';
        this.loginTo = '/home/Login';
      }
      if (localStorage.getItem('accountType') == 'BUY') {
        this.accountType = true;
      } else {
        this.accountType = false;
      }
      this.isLogin = Boolean(localStorage.getItem('loginName'));
      this.userInfo = JSON.parse(localStorage.getItem('userInfo') || '{}');

      this.routeMetaName = this.$route.meta.name;
    }
  }
};
</script>

<style lang="less" scoped>
.header {
  position: relative;
  .line {
    margin: 0 10px;
    color: #bbb;
  }
  .hover-color:hover {
    color: #61b6f2;
    cursor: pointer;
  }
  &-container {
    width: 1200px;
    // height: 200px;
    margin: 0 auto;
    .top {
      color: #606266;
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 30px;
      background: #f2f2f2;
      li {
        float: left;
      }
    }
    .strip {
      position: absolute;
      left: 0;
      width: 100%;
      background: #0a0c0b;
      overflow: hidden;
      &-container {
        cursor: pointer;
        display: flex;
        align-items: center;
        width: 1200px;
        height: 110px;
        margin: 0 auto;
      }
      &-bigcont {
        height: 42px;
        border: 2px solid #409eff;
        background-color: #409eff;
        border-radius: 5px;
        margin: 0 125px 0 160px;
      }
      &-input {
        border: none;
        display: inline-block;
        height: 100%;
        width: 497px;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        &:focus {
          outline: 1px solid #409eff;
        }
      }
      &-search {
        font-size: 23px;
        color: #fff;
        width: 72px;
        text-align: center;
        vertical-align: middle;
      }
      .btn-register {
        width: 85px;
        height: 42px;
        background-color: #000;
        border-color: #fff;
      }
      .btn-login {
        width: 85px;
        height: 42px;
        color: #409eff;
        background-color: #fff;
        border: none;
      }
    }
    .tab {
      border-bottom: none;
      position: absolute;
      top: 140px;
      left: 0;
      width: 100%;
      background-color: #fff;
      &-main {
        margin: 0 auto;
        width: 1200px;
        display: flex;
      }
    }
  }
}

.message-font {
  &.new {
    position: relative;
    &::after {
      content: '';
      position: absolute;
      top: -2px;
      right: -4px;
      width: 6px;
      height: 6px;
      background: orangered;
      border-radius: 50%;
    }
  }
}

.selected {
  background-color: #f0f8fe;
  color: #008df2 !important;
}

.all-cate {
  width: 208px;
  background-image: linear-gradient(to right, #00a9ff, #0a7def);
  position: relative;
  font-weight: 500;
  .all-cate-tit {
    text-align: center;
    height: 59px;
    line-height: 59px;
    cursor: pointer;
    box-sizing: border-box;
    position: relative;
    font-size: 14px;
    color: #fff;
    .all-cate-tit-img {
      position: absolute;
      left: 26%;
      top: 23px;
    }
  }
  .all-cate-box {
    position: absolute;
    left: 0;
    top: 59px;
    z-index: 10;
    font-size: 14px;
    color: #fff;
    .cate-list {
      height: 447px;
      overflow-x: hidden;
      overflow-y: auto;
      width: 208px;
      cursor: pointer; // background-image: linear-gradient(1204deg, #008cf4, #47a8ca);
      background: #008cf4;
      .cate-item {
        box-sizing: border-box;
        line-height: 40px;
        padding: 0 16px 0 30px;
        box-sizing: border-box;
        font-weight: normal;
        .cate-item-name {
          display: flex;
          align-items: center;
          justify-content: space-between;
          .icon {
            color: #fff;
            font-size: 16px;
          }
        }
        &:hover {
          color: #2ca2c6;
          background: #fff;
          .icon {
            transform: rotate(180deg);
            transition: all 0.4s ease;
            color: #999;
          }
        }
        &.isHover {
          color: #008cf4;
          background: #fff;
          .icon {
            transform: rotate(180deg);
            color: #999;
          }
        }
      }
    }
    .cate-content {
      position: absolute;
      left: 208px;
      top: 0px;
      width: 792px;
      background: #fff;
      height: 448px;
      padding: 10px 24px;
      box-sizing: border-box;
      overflow-y: auto;
      display: flex;
      flex-wrap: wrap;
      align-content: flex-start;
      .child-box {
        margin: 5px 15px 15px;
        height: 20px;
        cursor: pointer;
        color: #333;
        .child-name {
          font-size: 14px;
          margin-bottom: 14px;
          &:hover {
            color: #008cf4;
          }
        }
        .child-item {
          display: flex;
          margin-bottom: 30px;
          .child-item-name {
            font-size: 14px;
            margin-right: 40px;
            &:hover {
              color: #008cf4;
            }
          }
        }
      }
    }
  }
}

.header-nav {
  width: 140px;
  height: 60px;
  line-height: 60px;
  text-align: center;
  color: #333;
  box-sizing: border-box;
  text-align: center;
  font-size: 14px;
  cursor: pointer;
  &:hover {
    color: #008cf4;
  }
}

.message-drawer {
  /deep/ .el-drawer__body {
    overflow: auto;
  }
}

.message-list {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  .message-item {
    padding: 8px 12px 8px 18px;
    box-sizing: border-box;
    box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.5);
    &.new {
      position: relative;
      &::before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 6px;
        margin: auto 0;
        width: 6px;
        height: 6px;
        background: orangered;
        border-radius: 50%;
      }
    }
    .title {
      font-size: 13px;
      font-weight: bold;
      color: #333;
      word-break: break-all;
    }
    .content {
      margin-top: 4px;
      font-size: 13px;
      color: #666;
      word-break: break-all;
    }
    .date {
      margin-top: 6px;
      font-size: 13px;
      color: #888;
    }
  }
}
</style>

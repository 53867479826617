<template>
    <div class="nav-wrap">
        <div class="title">帮助中心</div>
        <div class="nav">
            <div v-for="items in nav" :key="items.name" class="nav-module">
                <div class="nav-title mouseStyle" @click="items.upOrDown = !items.upOrDown">
                    {{items.name}}
                    <img :src="items.upOrDown ? icon[1] : icon[0]" alt=""
                        :class="[items.upOrDown ? 'nav-down' : 'nav-right']">
                </div>

                <div v-show="items.upOrDown">
                    <router-link v-for="item in items.child" :to='"/Doc/details/" + item.path' :key="item.path">
                        <div class="nav-item mouseStyle" :class="{selected: selected == item.path}"
                            @click="selected = item.path">
                            {{item.name}}
                        </div>
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {
        helpdoc
    } from '../api'

    export default {
        created() {
            console.log(this.$route)
            let _this = this
            helpdoc().then(e => {
                if (e.errno == 0) {
                    e.data.data.forEach(res => {
                        switch (res.type) {
                            case 'BZZX':
                                _this.format('BZZX', res)
                                break;
                            case 'PTGZ':
                                _this.format('PTGZ', res)
                                break;
                            case 'HTMB':
                                _this.format('HTMB', res)
                                break;
                            case 'ZFFS':
                                _this.format('ZFFS', res)
                                break;
                            default:
                        }
                    });
                } else {
                    this.$message()
                }
            })
        },
        data() {
            return {
                icon: [
                    require('@/assets/Doc/right.png'),
                    require('@/assets/Doc/down.png'),
                ],
                nav: {},
                selected: ''
            }
        },
        methods: {
            format(type, res) {
                const parsing = {
                    BZZX: '用户手册',
                    PTGZ: '平台规则',
                    HTMB: '合同模板',
                    ZFFS: '支付方式',
                }
                if (!this.nav[type]) {
                    let nav = {
                        ...this.nav
                    }
                    nav[type] = {
                        name: parsing[type],
                        upOrDown: false,
                        child: []
                    }
                    this.nav = nav
                }
                if (this.$route.params.id == res.title) {
                    this.nav[type].upOrDown = true
                    this.selected = this.$route.params.id
                }

                this.nav[type].child.push({
                    name: res.title,
                    path: res.title
                })
            }
        }
    }
</script>

<style lang="less" scoped>
    .nav-wrap {
        width: 235px;
        box-sizing: border-box;
        line-height: 1;
        position: relative;


        .title {
            width: 235px;
            font-size: 30px;
            background: #03a0fc;
            padding: 30px 0 30px 16px;
            color: #fff;
            height: 100px;
            box-sizing: border-box;
            position: absolute;
            top: -100px;
        }

        .nav {
            background: #fff;
            width: 235px;
            box-sizing: border-box;
            min-height: 300px;
            padding-bottom: 20px;

            .nav-module {
                position: relative;
                border-bottom: 1px solid #F7f7f7;
                 &:last-child{
                     border-bottom:none
                }
                .nav-title {
                    font-size: 20px;
                    padding: 22px 0 22px 38px;
                    color: #000;
                    position: relative;

                    &::before {
                        content: '';
                        background: #03a0fc;
                        width: 8px;
                        height: 8px;
                        border-radius: 50%;
                        display: block;
                        position: absolute;
                        top: 28px;
                        left: 22px;
                    }

                    img {

                        &.nav-right {
                            width: 10px;
                            height: 18px;
                            top: 24px;
                        }

                        &.nav-down {
                            width: 18px;
                            height: 10px;
                            top: 28px;
                        }

                        position: absolute;
                        right: 22px;
                    }
                }

                .nav-item {
                    cursor: pointer;
                    font-size: 16px;
                    color: #999;
                    position: relative;
                    padding: 16px 0 16px 36px;
                    // border-bottom: 1px solid #f7f7f7;
                }
            }
        }
    }

    .selected {
        background: #edf8ff;
        color: #333!important;
        &::after {
            content: '';
            background: #03a0fc;
            width: 2px;
            height: 20px;
            display: block;
            position: absolute;
            top: 15px;
            right: 0px;
        }
    }
</style>

/**
 * 请求错误状态码提示
 */

 const httpStatus = [
    { code: 404, msg: '请求资源未找到：404'},
    { code: 403, msg: '无访问权限：403'},
    { code: 500, msg: '服务器错误：500'},
    { code: 503, msg: '服务器错误：503'},
    { code: 504, msg: '服务器错误：504'},
  ]
 
  export default httpStatus
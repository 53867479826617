// 开发环境配置
const dev_config = {
    baseUrl: '',
    project: '',
    url: ''
}

// 生产环境配置
const production_config = {
    baseUrl: '',
    project: '',
    url: ''
}

module.exports = process.env.NODE_ENV === 'production' ? production_config : dev_config;
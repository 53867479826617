import Vue from 'vue';
import VueRouter from 'vue-router';
import DocRouter from '@/modules/Doc/index.router.js';
import HomeRouter from '@/modules/Home/home.router.js';
import MallRouter from '@/modules/Mall/index.router.js';
import NoticeRouter from '@/modules/Notice/index.router.js';
import UserRouter from '@/modules/User/index.router.js';

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err);
};

Vue.use(VueRouter);

var _ = require('lodash');

// // require.context 自动导入模块(webpack的api) 参数：路径 、 是否遍历 、 匹配规则（正则匹配）
let routeFiles = require.context('@/modules', true, /\.router\.js$/);

// 无权限路由，所有权限都能看到
let configRoutes = [];

// 全局路由，不需要头部和侧边栏的路由页面
let globalRoutes = [];

// 权限路由
let asyncRoutes_tmp = [];
// routeFiles.keys() 为导入的模块的路径
routeFiles.keys().forEach(key => {
  if (key === '') return;
  // routeFiles(key).default 为路由配置对象
  let routes = routeFiles(key).default;
  if (_.isArray(routes)) {
    routes.forEach(item => {
      // console.log(item,'子路由配置对象')
      pushRoute(item);
    });
  }
});

// 分类路由配置
function pushRoute(route) {
  //   console.log(route);
  if (route.type === 'global') {
    globalRoutes.push(route);
  } else if (route.type === 'lock') {
    configRoutes.push(route);
  } else if (route.name) {
    // 存储有权限的路由
    asyncRoutes_tmp.push(route);
  } else if (route.path) {
  }
}
const routes = [
  // {
  //   path: '/',
  //   redirect: '/home/HomePage',
  //   component: () => import('@/layout/index.vue'),
  //   children: [ ...HomeRouter, ...MallRouter, ...NoticeRouter]
  // },
  // ...DocRouter,
  // ...UserRouter,
  // {
  //   path: '/home/UserRegister',
  //   name: 'UserRegister',
  //   component: () => import('@/modules/Home/components/UserRegister.vue'),
  //   meta: {
  //     name: '用户注册'
  //   }
  // },
  {
    path: '/',
    redirect: '/DeviceLogin'
  },
  {
    path: '/DeviceMap',
    component: () => import('@/modules/DeviceMap/index.vue')
  },
  {
    path: '/DeviceLogin',
    component: () => import('@/modules/DeviceLogin/index.vue')
  },
  {
    path: '/traceability',
    name: 'traceability',
    component: () => import('@/modules/DeviceMap/traceability.vue')
  },
  // {
  //   path: '/ForgetPassword',
  //   component: ()=> import('@/modules/Login/ForgetPassword.vue'),
  // },
  {
    path: '*',
    component: () => import('@/modules/404/index.vue')
  }
];

const router = new VueRouter({
  routes
});

export default router;

import server from '@/api'

// 获取二级二级菜单
export function helpdoc(param) {
    return server.help.helpdoc(param);
}

// 获取文章
export function selectOne(params) {
    return server.help.selectOne(params);
}



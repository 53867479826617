const routes = [
  {
    // 商品超市
    path: '/home/Mall',
    name: 'Mall',
    component: () => import('./index.vue'),
    meta: {
      name: '商品超市'
    }
  },
  {
    // 商品详情
    path: '/home/goodsDetails',
    name: 'goodsDetails',
    component: () => import('./components/goodsDetails.vue'),
    meta: {
      name: '商品详情'
    }
  },
  {
    // 采购单
    path: '/home/Mall/ShoppingList',
    name: 'ShoppingList',
    component: () => import('./components/ShoppingList.vue'),
    meta: {
      name: '商品超市'
    }
  },
  {
    // 购买结果展示
    path: '/home/Mall/ShoppingList/Result',
    name: 'ShoppingListResult',
    component: () => import('./components/Result.vue'),
    meta: {
      name: '商品超市'
    }
  },
  {
    // 确认订单信息
    path: '/home/Mall/ShoppingList/ConfirmOrder',
    name: 'ShoppingListConfirmOrder',
    component: () => import('./components/ConfirmOrder.vue'),
    meta: {
      name: '商品超市'
    }
  },
  {
    // 店铺详情
    path: '/home/Mall/StoreDetail',
    name: 'StoreDetail',
    component: () => import('./components/storeDetail.vue'),
    meta: {
      name: '店铺详情'
    }
  },
  {
    // 成功下单
    path: '/home/Mall/Paymented',
    name: 'Paymented',
    component: () => import('./components/Paymented.vue'),
    meta: {
      name: '商品超市'
    }
  },
  {
    // 确认订单信息 -- 竞卖中心
    path: '/home/Bidding/ConfirmOrder',
    name: 'BiddingConfirmOrdera',
    component: () => import('./components/ConfirmOrder.vue'),
    meta: {
      // name: '竞卖中心'
    }
  },
  {
    // 成功下单 -- 竞卖中心
    path: '/home/Bidding/Paymented',
    name: 'BiddingPaymented',
    component: () => import('./components/Paymented.vue'),
    meta: {
      // name: '竞卖中心'
    }
  },
  {
    // 成功下单 -- 订单详情
    path: '/User/MyOrderInfo',
    name: 'MyOrderInfo',
    component: () => import('./components/Paymented.vue'),
    meta: {
      name: '订单详情'
    }
  }
];

export default routes;

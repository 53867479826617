var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "wrap" },
    [
      _c("div", { staticClass: "Doc-header" }, [
        _c(
          "div",
          { staticStyle: { background: "#f2f2f2" } },
          [_c("Header", { attrs: { onlyTop: true } })],
          1
        ),
        _c("div", { staticClass: "Doc-header-logo-wrap" }, [
          _c(
            "div",
            { staticClass: "Doc-header-logo" },
            [
              _c("router-link", { attrs: { to: "/home/HomePage" } }, [
                _c("img", {
                  staticStyle: { height: "40px" },
                  attrs: { src: require("@/assets/login-logo.png"), alt: "" },
                }),
              ]),
              _c(
                "router-link",
                {
                  staticClass: "Doc-header-right",
                  attrs: { to: "/home/HomePage" },
                },
                [
                  _vm._v("返回商城 "),
                  _c("i", { staticClass: "el-icon-arrow-right" }),
                ]
              ),
            ],
            1
          ),
        ]),
        _c("img", {
          staticStyle: { width: "100%", "vertical-align": "middle" },
          attrs: { src: require("@/assets/Doc/bg.png"), alt: "" },
        }),
      ]),
      _c("div", { staticClass: "main" }, [_c("router-view")], 1),
      _c("Foot"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
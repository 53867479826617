
import server from '../server/index.js'
import api from './config.js'
//2.引入
// console.log(server,'从server文件夹内导入server')
server.parseRouter('login', api.login)
server.parseRouter('goodsInfo',api.goodsInfo)
server.parseRouter('category',api.category)
server.parseRouter('proProduct',api.proProduct)
server.parseRouter('users',api.users)
//企业相关
server.parseRouter('clientInfo',api.clientInfo)
server.parseRouter('cart',api.cart)
//竞卖相关
server.parseRouter('autionInfo',api.autionInfo)
//订单
server.parseRouter('order',api.order)
server.parseRouter('brand',api.brand)
//热门相关
server.parseRouter('hotAution',api.hotAution)

server.parseRouter('supplier',api.supplier)
//合作银行
server.parseRouter('bankInfo',api.bankInfo)

// server.parseRouter('upstart',api.upstart)
//文件上传
server.parseRouter('storage',api.storage)

// 用户全部权限
server.parseRouter('permission', {
    getPermission: {
        url: "wxtlogin/user/tesAllPermissions",
        type: 'post',
    }
})


//我的竞卖记录相关
server.parseRouter('autionDeal',api.autionDeal)
//收货地址
server.parseRouter('address',api.address)
//行政区域
server.parseRouter('region',api.region)
//证书管理
server.parseRouter('certificate',api.certificate)
//竞标
server.parseRouter('tender',api.tender)
//南海灵通铝锭
server.parseRouter('lvDingPrice',api.lvDingPrice)

// 企业设备
server.parseRouter('equipment',api.equipment)

// 帮助中心
server.parseRouter('help',api.help)

// 管理子账号
server.parseRouter('account_management',api.account_management)


// 朔源查询
server.parseRouter('other',api.other)

export default server




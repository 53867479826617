import Doc from './index.vue';

const routes = [{
    path: '/Doc',
    name: 'doc',
    component: Doc,
    redirect: '/Doc/synopsis',
    children: [{
      path: '/Doc/details',
      name: 'Doc-details',
      redirect: '/Doc/synopsis',
      component: () => import('./components/details.vue'),
      meta: {
        FirstLv: '详情',
      },
      children: [{
          path: '/Doc/details/:id',
          name: 'InvoiceeExplain',
          component: () => import('./components/article.vue'),
          meta: {
            FirstLv: '文章',
            secondLv: 'InvoiceeExplain'
          }
        }
      ]
    }, {
      path: '/Doc/synopsis',
      name: 'synopsis',
      component: () => import('./components/synopsis.vue'),
      meta: {
        FirstLv: '快捷服务',
        secondLv: 'synopsis'
      }
    }]

  },

];

export default routes;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "header" }, [
    _c(
      "div",
      {
        staticClass: "header-container",
        style: { height: !_vm.onlyTop ? "200px" : "" },
      },
      [
        _c("div", { staticClass: "top" }, [
          _c(
            "div",
            [
              !_vm.isLogin ? _c("span", [_vm._v("你好，请")]) : _vm._e(),
              _c(
                "span",
                {
                  staticClass: "hover-color",
                  on: {
                    click: function ($event) {
                      return _vm.routerTo(_vm.loginTo)
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.loginTitle))]
              ),
              _c("span", { staticClass: "line" }, [_vm._v("|")]),
              _c("router-link", { attrs: { to: _vm.registerTo } }, [
                _c(
                  "span",
                  {
                    staticClass: "hover-color",
                    staticStyle: { color: "#606266" },
                    on: {
                      click: function ($event) {
                        return _vm.switchLoginOut()
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.register))]
                ),
              ]),
            ],
            1
          ),
          _c("ul", [
            _c("li", [
              _c(
                "span",
                {
                  staticClass: "hover-color",
                  on: {
                    click: function ($event) {
                      return _vm.routerTo("/home/HomePage")
                    },
                  },
                },
                [_vm._v("商场首页")]
              ),
              _c("span", { staticClass: "line" }, [_vm._v("|")]),
            ]),
            _vm.myStaus == "BUY"
              ? _c("li", [
                  _c(
                    "span",
                    {
                      staticClass: "hover-color",
                      on: {
                        click: function ($event) {
                          return _vm.routerTo("/home/SupplierNotice")
                        },
                      },
                    },
                    [_vm._v("供应商入驻")]
                  ),
                  _c("span", { staticClass: "line" }, [_vm._v("|")]),
                ])
              : _vm._e(),
            _c(
              "li",
              [
                _c(
                  "el-dropdown",
                  [
                    _c(
                      "span",
                      {
                        staticClass: "hover-color el-dropdown-link mouseStyle",
                        on: {
                          click: function ($event) {
                            return _vm.myCenter("BUY", "/User/workbench")
                          },
                        },
                      },
                      [
                        _vm._v("买家中心"),
                        _c("i", {
                          staticClass: "el-icon-arrow-down el-icon--right",
                        }),
                      ]
                    ),
                    _c(
                      "el-dropdown-menu",
                      { attrs: { slot: "dropdown" }, slot: "dropdown" },
                      [
                        _c("el-dropdown-item", [
                          _c(
                            "span",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.myCenter("BUY", "/User/Publish")
                                },
                              },
                            },
                            [_vm._v("发布竞买")]
                          ),
                        ]),
                        _c("el-dropdown-item", [
                          _c(
                            "span",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.myCenter("BUY", "/User/ReqOrder", {
                                    customer: "buyder",
                                  })
                                },
                              },
                            },
                            [_vm._v("竞买订单")]
                          ),
                        ]),
                        _c("el-dropdown-item", [
                          _c(
                            "span",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.myCenter("BUY", "/User/MyPublish")
                                },
                              },
                            },
                            [_vm._v("我的竞买")]
                          ),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c("span", { staticClass: "line" }, [_vm._v("|")]),
              ],
              1
            ),
            _c(
              "li",
              [
                _c(
                  "el-dropdown",
                  [
                    _c(
                      "span",
                      {
                        staticClass: "hover-color el-dropdown-link mouseStyle",
                        on: {
                          click: function ($event) {
                            return _vm.myCenter("SELL", "/User/workbench")
                          },
                        },
                      },
                      [
                        _vm._v("供应商中心"),
                        _c("i", {
                          staticClass: "el-icon-arrow-down el-icon--right",
                        }),
                      ]
                    ),
                    _c(
                      "el-dropdown-menu",
                      { attrs: { slot: "dropdown" }, slot: "dropdown" },
                      [
                        _c("el-dropdown-item", [
                          _c(
                            "span",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.myCenter(
                                    "SELL",
                                    "/User/PublishBidding"
                                  )
                                },
                              },
                            },
                            [_vm._v("发布竞卖")]
                          ),
                        ]),
                        _c("el-dropdown-item", [
                          _c(
                            "span",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.myCenter(
                                    "SELL",
                                    "/User/ReqOrder",
                                    { customer: "sellder" }
                                  )
                                },
                              },
                            },
                            [_vm._v("竞卖订单")]
                          ),
                        ]),
                        _c("el-dropdown-item", [
                          _c(
                            "span",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.myCenter("SELL", "/User/MyBidding")
                                },
                              },
                            },
                            [_vm._v("我的竞卖")]
                          ),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c("span", { staticClass: "line" }, [_vm._v("|")]),
              ],
              1
            ),
            _c("li", [
              _c(
                "span",
                {
                  staticClass: "hover-color",
                  on: {
                    click: function ($event) {
                      return _vm.$router.push("/DeviceLogin")
                    },
                  },
                },
                [_vm._v("服务商中心")]
              ),
              _c("span", { staticClass: "line" }, [_vm._v("|")]),
            ]),
            _c("li", [
              _c(
                "span",
                {
                  staticClass: "hover-color",
                  on: { click: _vm.toFinanceSystem },
                },
                [_vm._v("企业服务中心")]
              ),
              _c("span", { staticClass: "line" }, [_vm._v("|")]),
            ]),
            _c("li", [_vm._v("客服热线：400-1620-620")]),
          ]),
        ]),
        !_vm.onlyTop
          ? [
              _c("div", { staticClass: "strip" }, [
                _c("div", { staticClass: "strip-container" }, [
                  _c("img", {
                    staticStyle: { height: "65px" },
                    attrs: { src: require("@/assets/logo2.png"), alt: "" },
                    on: {
                      click: function ($event) {
                        return _vm.routerTo("/home/HomePage")
                      },
                    },
                  }),
                  _c("div", { staticClass: "strip-bigcont" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.searchItem,
                          expression: "searchItem",
                        },
                      ],
                      staticClass: "strip-input",
                      attrs: { type: "text" },
                      domProps: { value: _vm.searchItem },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.searchItem = $event.target.value
                        },
                      },
                    }),
                    _c("span", {
                      staticClass: "el-icon-search strip-search",
                      on: {
                        click: function ($event) {
                          return _vm.search()
                        },
                      },
                    }),
                  ]),
                  !_vm.isLogin
                    ? _c(
                        "div",
                        [
                          _c(
                            "el-button",
                            {
                              staticClass: "btn-register",
                              attrs: { type: "primary" },
                              on: {
                                click: function ($event) {
                                  return _vm.routerTo("/home/UserRegister")
                                },
                              },
                            },
                            [
                              _c("span", { staticStyle: { color: "#fff" } }, [
                                _vm._v("注册"),
                              ]),
                            ]
                          ),
                          _c(
                            "el-button",
                            {
                              staticClass: "btn-login",
                              attrs: { type: "primary" },
                              on: {
                                click: function ($event) {
                                  return _vm.routerTo("/home/Login")
                                },
                              },
                            },
                            [
                              _c(
                                "span",
                                { staticStyle: { color: "#409eff" } },
                                [_vm._v("登录")]
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ]),
              ]),
              _c("div", { staticClass: "tab" }, [
                _c(
                  "div",
                  { staticClass: "tab-main" },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "all-cate",
                        on: {
                          mouseenter: function ($event) {
                            _vm.categoriesList = true
                          },
                          mouseleave: _vm.mouseleave,
                        },
                      },
                      [
                        _c("router-link", { attrs: { to: "/home/Mall" } }, [
                          _c("p", { staticClass: "all-cate-tit" }, [
                            _c("img", {
                              staticClass: "all-cate-tit-img",
                              attrs: { src: require("@/assets/nav-logo.png") },
                            }),
                            _vm._v("产品类目"),
                          ]),
                        ]),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.categoriesList,
                                expression: "categoriesList",
                              },
                            ],
                            staticClass: "all-cate-box",
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "cate-list" },
                              _vm._l(_vm.cateList, function (item, index) {
                                return _c(
                                  "div",
                                  {
                                    key: item.name,
                                    class: [
                                      "cate-item",
                                      {
                                        isHover: _vm.selCateName === item.name,
                                      },
                                    ],
                                    on: {
                                      mouseenter: function ($event) {
                                        _vm.selCateName = item.name
                                        _vm.cateIndex = index
                                      },
                                      click: function ($event) {
                                        return _vm.clickCate(item)
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "cate-item-name" },
                                      [
                                        _c(
                                          "p",
                                          {
                                            staticStyle: {
                                              "font-size": "14px",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " " + _vm._s(item.name) + " "
                                            ),
                                          ]
                                        ),
                                        _c("i", {
                                          staticClass:
                                            "el-icon-arrow-right icon",
                                        }),
                                      ]
                                    ),
                                  ]
                                )
                              }),
                              0
                            ),
                            _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.selCateName !== "",
                                    expression: "selCateName !== ''",
                                  },
                                ],
                                staticClass: "cate-content",
                              },
                              _vm._l(
                                _vm.cateList[_vm.cateIndex].child,
                                function (childItem) {
                                  return _c(
                                    "div",
                                    {
                                      key: childItem.name,
                                      staticClass: "child-box",
                                    },
                                    [
                                      _c(
                                        "p",
                                        {
                                          staticClass: "child-name",
                                          on: {
                                            click: function ($event) {
                                              return _vm.clickCate(childItem)
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " + _vm._s(childItem.name) + " "
                                          ),
                                        ]
                                      ),
                                    ]
                                  )
                                }
                              ),
                              0
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                    _vm._l(_vm.nav, function (item) {
                      return _c(
                        "div",
                        {
                          key: item.path,
                          class: [
                            "header-nav",
                            _vm.selected == item.name ? "selected" : "",
                          ],
                          on: {
                            click: function ($event) {
                              return _vm.goTo(item.path, item)
                            },
                          },
                        },
                        [_vm._v(" " + _vm._s(item.name) + " ")]
                      )
                    }),
                  ],
                  2
                ),
              ]),
              _c(
                "el-drawer",
                {
                  staticClass: "message-drawer",
                  staticStyle: { height: "100%" },
                  attrs: {
                    "custom-class": "message-drawer",
                    title: "消息中心",
                    visible: _vm.isShowMessage,
                    direction: "rtl",
                    "before-close": _vm.handleCloseMessage,
                  },
                  on: {
                    "update:visible": function ($event) {
                      _vm.isShowMessage = $event
                    },
                  },
                },
                [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "infinite-scroll",
                          rawName: "v-infinite-scroll",
                          value: _vm.loadMessage,
                          expression: "loadMessage",
                        },
                      ],
                      staticClass: "message-list",
                    },
                    _vm._l(_vm.messageManage.list, function (item) {
                      return _c(
                        "div",
                        {
                          key: item.msgId,
                          staticClass: "message-item",
                          class: { new: item.readStatus == 2 },
                        },
                        [
                          _c("div", { staticClass: "title" }, [
                            _vm._v(" " + _vm._s(item.msgTitle) + " "),
                          ]),
                          _c("div", { staticClass: "content" }, [
                            _vm._v(" " + _vm._s(item.msgContent) + " "),
                          ]),
                          _c("div", { staticClass: "date" }, [
                            _vm._v(_vm._s(item.sendTime)),
                          ]),
                        ]
                      )
                    }),
                    0
                  ),
                ]
              ),
            ]
          : _vm._e(),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
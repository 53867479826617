var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "nav-wrap" }, [
    _c("div", { staticClass: "title" }, [_vm._v("帮助中心")]),
    _c(
      "div",
      { staticClass: "nav" },
      _vm._l(_vm.nav, function (items) {
        return _c("div", { key: items.name, staticClass: "nav-module" }, [
          _c(
            "div",
            {
              staticClass: "nav-title mouseStyle",
              on: {
                click: function ($event) {
                  items.upOrDown = !items.upOrDown
                },
              },
            },
            [
              _vm._v(" " + _vm._s(items.name) + " "),
              _c("img", {
                class: [items.upOrDown ? "nav-down" : "nav-right"],
                attrs: {
                  src: items.upOrDown ? _vm.icon[1] : _vm.icon[0],
                  alt: "",
                },
              }),
            ]
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: items.upOrDown,
                  expression: "items.upOrDown",
                },
              ],
            },
            _vm._l(items.child, function (item) {
              return _c(
                "router-link",
                { key: item.path, attrs: { to: "/Doc/details/" + item.path } },
                [
                  _c(
                    "div",
                    {
                      staticClass: "nav-item mouseStyle",
                      class: { selected: _vm.selected == item.path },
                      on: {
                        click: function ($event) {
                          _vm.selected = item.path
                        },
                      },
                    },
                    [_vm._v(" " + _vm._s(item.name) + " ")]
                  ),
                ]
              )
            }),
            1
          ),
        ])
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import ElementUI from 'element-ui';
import * as echarts from 'echarts';
import moment from 'moment';
import lodash from 'lodash';
import 'element-ui/lib/theme-chalk/index.css';
import './styles/index.less';
import './filter';
import './utils/rem';
import Bus from './bus';
import axios from 'axios';
import publicMethod from './assets/js/public';
import permission from '@/directive/permission';

Vue.prototype.publicMethod = publicMethod;
Vue.prototype.$moment = moment;
Vue.prototype.$L = lodash;
Vue.prototype.$bus = Bus;
Vue.prototype.$axios = axios;
Vue.prototype.$echarts = echarts;
Vue.config.productionTip = false;
Vue.use(ElementUI);

Vue.directive('permission', permission);

router.beforeEach((to, from, next) => {
  if (window.scrollY != 0) {
    window.scroll(0, 0);
  }

  /* 路由发生变化修改页面title */
  if (to.meta.name) {
    document.title = '华旭大数据云平台 - ' + to.meta.name;
  } else {
    document.title = '华旭大数据云平台';
  }
  next();
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');

<template>
  <div class="foot">
    <!-- <div class="footer-main">
      <span class="left">深圳链融易技术支持 ©Copyright Helvera Tech,Co. Ltd. All Rights Reserved.</span>
      <span>版权所有 ©Copyright Linkeasier Tech,Co. Ltd. All Rights Reserved.</span>
    </div> -->
    <div class="footer-main bootom">
      <a style="margin-right:4px" href="https://beian.miit.gov.cn/#/Integrated/index"><img
          src="@/assets/Police.png"></a>
      <a href="https://beian.miit.gov.cn/#/Integrated/index">粤ICP备2022026702号-1</a>
      <span style="margin:0 10px">|</span>
      <span>Technical support</span>
      <span style="font-size:20px;">©</span>
      <span>深圳前海汇金融服务有限公司技术支持. Copyright</span>
      <span style="font-size:20px">©</span>
      <span>汕头华侨试验区华旭科技有限公司版权所有</span>
      <!-- <span style="margin:0 10px">|</span>
      <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44030502008409">
        <span>粤公网安备：44030502008409号</span>
      </a> -->
    </div>
  </div>
</template>

<script>
  export default {};
</script>

<style lang="less" scoped>
  .foot {
    width: 100%;
    padding: 10px 0;

    .footer-main {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 12px;
      color: #ddd;
      text-align: center;

      img {
        margin-right: 4px;
      }

      a {
        color: #ddd;
      }

      .copyright {
        margin-left: 20px;
      }

      &.bootom {
        margin-top: 6px;
      }

      .left {
        margin-right: 40px;
      }
    }
  }
</style>
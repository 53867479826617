var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "foot" }, [
      _c("div", { staticClass: "footer-main bootom" }, [
        _c(
          "a",
          {
            staticStyle: { "margin-right": "4px" },
            attrs: { href: "https://beian.miit.gov.cn/#/Integrated/index" },
          },
          [_c("img", { attrs: { src: require("@/assets/Police.png") } })]
        ),
        _c(
          "a",
          { attrs: { href: "https://beian.miit.gov.cn/#/Integrated/index" } },
          [_vm._v("粤ICP备2022026702号-1")]
        ),
        _c("span", { staticStyle: { margin: "0 10px" } }, [_vm._v("|")]),
        _c("span", [_vm._v("Technical support")]),
        _c("span", { staticStyle: { "font-size": "20px" } }, [_vm._v("©")]),
        _c("span", [_vm._v("深圳前海汇金融服务有限公司技术支持. Copyright")]),
        _c("span", { staticStyle: { "font-size": "20px" } }, [_vm._v("©")]),
        _c("span", [_vm._v("汕头华侨试验区华旭科技有限公司版权所有")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
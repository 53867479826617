import localConfig from './config'; //  生产环境还是开发环境配置
import httpStatus from './type'; //  http状态码及对应的消息
import Server from './Server.js';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import Vue from 'vue';
import {Message} from 'element-ui';
import {MessageBox} from 'element-ui';
import router from '@/router/index';
import {API_PREFIX} from '@/api/config';
Vue.use(Loading);

const config = {
  baseUrl: localConfig.url,
  timeOut: 15000
};

let server = new Server({ 
  baseUrl: config.baseUrl,
  timeOut: config.timeOut
});

server.bindLoading(Vue.$loading);

// 前后拦截
server.interceptors.request.use(requestSuccess, requestError);

server.interceptors.response.use(responseSuccess, responseError);

function requestSuccess(config) {
  // 开启遮罩层
  if (!config.hideLoading && config.show) {
    server.loading.show();
  }

  if( config.url.indexOf('jshERP-boot') > -1 ) {
    config.url = `/${config.url}`;
  } else {
    config.url = `${API_PREFIX}/${config.url}`;
  }

  // 如果存在证书信息，则放到 header 里面
  if (localStorage.getItem('certInfo')) {
    config.headers.UKEY_SERIALNO = localStorage.getItem('certInfo');
  }

  // // 开发用，打包记得注销
  // localStorage.getItem('Token') ? config.headers['X-Dts-Admin-Token'] = localStorage.getItem('Token') : ''


  // 物质交付 和 朔源查询 需要
  config.url.indexOf('/jshERP-boot') > -1 ? config.headers['X-Access-Token'] = 'preset_D16x43eymc15cHfgcS2AWKO2SFy2wJO6' : ''

  return config
}

function requestError(error) {
  Promise.reject(error)
    .then(res => {})
    .catch(err => {
      if (err.config.show) {
        // 关闭弹窗
        server.loading.hide();
      }
    });

  return Promise.reject(error);
}

function responseSuccess(res) {
  if (res.config.show) {
    // 关闭弹窗
    server.loading.hide();
  }

  const status = res.status;
  let code ;
  // 溯源接口返回的 code 与 原来的接口的不同
  if( res.data.errno == undefined ) {
    console.log( res.data )
    code =  res.data.code == 200 ?  0 : res.data.code
    res.data.errmsg = res.data.msg
  } else {
    code = res.data.errno
  } 
    
  if (status === 200) {
    switch (code) {
      case 0: // 成功
        break;
      case -1: // 错误
        Message.warning(res.data.errmsg);
        break;
      case 401: // 参数不对
        Message.warning(res.data.errmsg);
        break;
      case 402: // 参数值不对
        Message.warning(res.data.errmsg);
        break;
      case 403: // 审核备注不能为空
        Message.warning(res.data.errmsg);
        break;
      case 501: // 请登录
        var isLogin = localStorage.getItem('loginName');
        if (isLogin != null) {
          Message.warning('登录过期，请登录');
          router.push({
            path: '/DeviceLogin?' + Date.now()
          });
        } else {
          Message.warning('未登录，请登录');
          router.push({
            path: '/DeviceLogin?' + Date.now()
          });
        }
        localStorage.clear();
        sessionStorage.clear();
        break;
      case 502: // 系统内部错误
        Message.warning(res.data.errmsg);
        break;
      case 503: // 业务不支持
        Message.warning(res.data.errmsg);
        break;
      case 504: // 更新数据已失效
        Message.warning(res.data.errmsg);
        break;
      case 505: // 更新数据失败
        Message.warning(res.data.errmsg);
        break;
      case 506: // 无操作权限
        Message.warning(res.data.errmsg);
        break;
      default:
        Message.warning(res.data.errmsg);
    }
    return res.data;
  } else if (status === 500) {
    Message.error('系统升级中');
  }

  // let i = 0,
  //   length = httpStatus.length,
  //   item = null;
  // for (; i < length; i++) {
  //   item = httpStatus[i]
  //   if (res.status === item.code) {
  //     console.log(itme.msg)
  //     return res.data
  //   }
  // }
}

function responseError(error) {
  Promise.reject(error)
    .then(res => {})
    .catch(err => {
      if (err.config.show) {
        // 关闭弹窗
        server.loading.hide();
      }
    });

  return Promise.reject(error);
}

export default server;

const routes = [
  {
    path: '/User',
    name: 'User',
    component: () => import('./new-index.vue'),
    children: [
      {
        path: '/User/AttestInfo',
        name: 'AttestInfo',
        component: () => import('./components/AttestInfo.vue'),
        meta: {
          FirstLv: '企业账户',
          secondLv: 'AttestInfo'
        }
      },
      {
        path: '/User/CorporateInfo',
        name: 'CorporateInfo',
        component: () => import('./components/enterprise/CorporateInfo.vue'),
        meta: {
          FirstLv: '企业账户',
          secondLv: 'CorporateInfo'
        }
      },
      {
        path: '/User/Address',
        name: 'Address',
        component: () => import('./components/address/Address.vue'),
        meta: {
          FirstLv: '收货地址',
          secondLv: 'Address'
        }
      },
      {
        path: '/User/AddOrModify',
        name: 'AddOrModify',
        component: () => import('./components/address/AddOrModify.vue'),
        meta: {
          FirstLv: '修改或增加',
          secondLv: 'AddOrModify'
        }
      },
      {
        path: '/User/CorporateInfo/EditPassword',
        name: 'CorporateInfo-EditPassword',
        component: () => import('./components/enterprise/EditPassword.vue'),
        meta: {
          FirstLv: '修改账户密码',
          secondLv: 'EditPassword'
        }
      },
      {
        path: '/User/CorporateInfo/BindingPhone',
        name: 'CorporateInfo-BindingPhone',
        component: () => import('./components/enterprise/BindingPhone.vue'),
        meta: {
          FirstLv: '重置绑定手机',
          secondLv: 'BindingPhone'
        }
      },
      {
        path: '/User/MyPublish',
        name: 'MyPublish',
        component: () => import('./components/publish/MyPublish.vue'),
        meta: {
          FirstLv: '竞买管理',
          secondLv: 'MyPublish'
        }
      },
      {
        path: '/User/MyPartPublish',
        name: 'MyPartPublish',
        component: () => import('./components/publish/MyPartPublish.vue'),
        meta: {
          FirstLv: '竞买管理',
          secondLv: 'MyPartPublish'
        }
      },
      {
        path: '/User/ConfirmOrder',
        name: 'ConfirmOrder',
        component: () => import('./components/publish/ConfirmOrder.vue'),
        meta: {
          FirstLv: '竞买管理'
          // secondLv: 'MyPublish'
        }
      },
      {
        path: '/User/auditResult',
        name: 'auditResult',
        component: () => import('./components/auditResult.vue'),
        meta: {
          FirstLv: '审核结果',
          secondLv: 'auditResult'
        }
      },
      {
        path: '/User/Publish',
        name: 'Publish',
        component: () => import('./components/publish/PublishBid'),
        meta: {
          FirstLv: '发布竞买',
          secondLv: 'Publish'
        }
      },
      {
        path: '/User/PublishSuccess',
        name: 'PublishSuccess',
        component: () => import('./components/publish/PublishSuccess.vue'),
        meta: {
          FirstLv: '',
          secondLv: 'PublishSuccess'
        }
      },
      {
        path: '/User/PublishDetail',
        name: 'PublishDetail',
        component: () => import('./components/publish/PublishDetail.vue'),
        meta: {
          FirstLv: '',
          secondLv: 'PublishDetail'
        }
      },
      {
        path: '/User/workbench',
        name: 'workbench',
        component: () => import('./components/workbench.vue'),
        meta: {
          FirstLv: '',
          secondLv: 'workbench'
        }
      },
      {
        path: '/User/taskList',
        name: 'taskList',
        component: () => import('./components/taskList.vue'),
        meta: {
          FirstLv: '',
          secondLv: 'taskList'
        }
      },
      {
        path: '/User/Paymented',
        name: 'User-Paymented',
        component: () => import('./components/order/Paymented.vue'),
        meta: {
          FirstLv: '',
          secondLv: 'Paymented'
        }
      },
      {
        path: '/User/SubAccount',
        name: 'SubAccount',
        component: () => import('./components/SubAccount.vue'),
        meta: {
          FirstLv: '企业账户',
          secondLv: 'SubAccount'
        }
      },
      // {
      //   path: '/User/PublishBidding',
      //   name: 'PublishBidding',
      //   component: () => import('./components/bidding/PublishBidding.vue'),
      //   meta: {
      //     FirstLv: '竞卖管理',
      //     secondLv: 'PublishBidding'
      //   }
      // },
       {
        path: '/User/PublishBidding',
        name: 'PublishBidding',
        component: () => import('./components/bidding/PublishBiddings'),
        meta: {
          FirstLv: '竞卖管理',
          secondLv: 'PublishBidding'
        }
      },
      

      {
        path: '/User/MyPartBidding',
        name: 'MyPartBidding',
        component: () => import('./components/bidding/MyPartBidding.vue'),
        meta: {
          FirstLv: '竞卖管理',
          secondLv: 'MyPartBidding'
        }
      },

      {
        path: '/User/MyBidding',
        name: 'MyBidding',
        component: () => import('./components/bidding/MyBidding.vue'),
        meta: {
          FirstLv: '竞卖管理',
          secondLv: 'MyBidding'
        }
      },
      {
        path: '/User/MyBiddingInfo',
        name: 'MyBiddingInfo',
        component: () => import('./components/bidding/MyBiddingInfo.vue'),
        meta: {
          FirstLv: '',
          secondLv: 'MyBiddingInfo'
        }
      },
      {
        path: '/User/StoreInfo',
        name: 'StoreInfo',
        component: () => import('./components/store/StoreInfo.vue')
      },
      {
        path: '/User/ProductList',
        name: 'ProductList',
        component: () => import('./components/product/list.vue')
      },
      {
        path: '/User/AddProduct',
        name: 'AddProduct',
        component: () => import('./components/product/add.vue')
      },
      {
        path: '/User/MyOrder',
        name: 'MyOrder',
        component: () => import('./components/order/MyOrder.vue')
      },
      {
        path: '/User/ReqOrder',
        name: 'ReqOrder',
        component: () => import('./components/order/ReqOrder.vue')
      },
      {
        path: '/User/TransactionDetail',
        name: 'TransactionDetail',
        component: () => import('./components/order/TransactionDetail.vue')
      },
      {
        path: '/User/MyContract',
        name: 'MyContract',
        component: () => import('./components/contract/mine.vue'),
        meta: {
          FirstLv: '我的合同',
          secondLv: 'MyContract'
        }
      },
      {
        path: '/User/SellMyContract',
        name: 'MyContract',
        component: () => import('./components/contract/mine.vue'),
        meta: {
          FirstLv: '我的合同',
          secondLv: 'MyContract'
        }
      },
      {
        path: '/User/ContractDetail',
        name: 'ContractDetail',
        component: () => import('./components/contract/detail.vue'),
        meta: {
          FirstLv: '合同详情',
          secondLv: 'ContractDetail'
            }
        },
        {
            path: '/User/MyCertificate',
            name: 'MyCertificate',
            component: () => import('./components/certificate/MyCertificate.vue'),
            meta: {
                FirstLv: '我的证书',
                secondLv: 'MyCertificate'
            }
        },
        {
            path: '/User/CertificateUsageRecord',
            name: 'CertificateUsageRecord',
            component: () => import('./components/certificate/CertificateUsageRecord.vue'),
            meta: {
                FirstLv: '证书使用记录',
                secondLv: 'CertificateUsageRecord'
        }
      },
   
      {
        path: '/User/PublishCompetitive',
        name: 'PublishCompetitive',
        component: () => import('./components/competitive/PublishCompetitive'),
        meta: {
          FirstLv: '发布竞标',
          secondLv: 'PublishCompetitive'
        }
      },
      {
        path: '/User/MyCompetitive',
        name: 'MyCompetitive',
        component: () => import('./components/competitive/mine.vue'),
        meta: {
          FirstLv: '我的竞标',
          secondLv: 'MyCompetitive'
        }
      },
      {
        path: '/User/BidOrder',
        name: 'BidOrder',
        component: () => import('./components/order/BidOrder.vue'),
        meta: {
          FirstLv: '竞标订单',
          secondLv: 'BidOrder'
        }
      },
      {
        path: '/User/MsgList',
        name: 'MsgList',
        component: () => import('./components/msgList.vue'),
        meta: {
          FirstLv: '消息列表',
          secondLv: 'MsgList'
        }
      },
      {
        path: '/User/BidOrderDetail',
        name: 'BidOrderDetail',
        component: () => import('./components/order/BidOrderDetail.vue'),
        meta: {
          FirstLv: '竞标订单详情',
          secondLv: 'BidOrderDetail'
        }
      },

      {
        path: '/User/MyPartCompetitive',
        name: 'MyPartCompetitive',
        component: () => import('./components/competitive/part.vue'),
        meta: {
          FirstLv: '参与的竞标',
          secondLv: 'MyPartCompetitive'
        }
      },
      {
        path: '/User/device',
        name: 'MyDevice',
        component: () => import('./components/device/index.vue'),
        meta: {
          FirstLv: '设备合同',
          secondLv: 'device'
        }
      },
      {
        path: '/User/device/add',
        name: 'DeviceAssociate',
        component: () => import('./components/device/component/add.vue'),
        meta: {
          FirstLv: '设备合同',
          secondLv: 'device'
        }
      },
      {
        path: '/User/device/associateOrUpDate',
        name: 'DeviceAssociate',
        component: () => import('./components/device/component/associateOrUpDate.vue'),
        meta: {
          FirstLv: '设备合同',
          secondLv: 'device'
        }
      },
      {
        path: '/User/device/check',
        name: 'DeviceAssociate',
        component: () => import('./components/device/component/associateOrUpDate.vue'),
        meta: {
          FirstLv: '设备合同',
          secondLv: 'device'
        }
      }
    ]
  }
];

export default routes;

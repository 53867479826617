const routes = [
  {
    path: '/home/HomePage',
    name: 'HomePage',
    component: () => import('./components/HomePage.vue'),
    meta: {
      name: '首页',
      fixed: true // 用于固定一级类目菜单
    }
  },
  {
    path: '/home/Bidding',
    name: 'Bidding',
    component: () => import('./components/Bidding.vue'),
    meta: {
      name: '竞卖中心'
    }
  },
  {
    path: '/home/LoogFor',
    name: 'LoogFor',
    component: () => import('./components/LoogFor.vue'),
    meta: {
      name: '竞买中心'
    }
  },
  {
    path: '/home/LoogForDetail',
    name: 'LoogForDetail',
    component: () => import('./components/LoogForDetail.vue'),
    meta: {
      name: '竞买详情'
    }
  },
  {
    path: '/home/loogForQuote',
    name: 'loogForQuote',
    component: () => import('./components/loogForQuote.vue'),
    meta: {
      name: '竞买报价'
    }
  },
  {
    path: '/home/Login',
    name: 'Login',
    component: () => import('@/modules/Login/index.vue'),
    meta: {
      name: '登录'
    }
  },


    //旧的登录页面
    {
        path: '/home/OldLogin',
        name: 'Login',
        component: () => import('./components/Login.vue'),
        meta: {
            name: '登录'
        }
    },


    {
    path: '/home/BiddingDetails',
    name: 'BiddingDetails',
    component: () => import('./components/BiddingDetails.vue'),
    meta: {
      name: '竞卖详情'
    }
  },

  {
    path: '/home/Tender',
    name: 'Tender',
    component: () => import('./components/Tender.vue'),
    meta: {
      name: '竞标中心'
    }
  },
  {
    path: '/home/TenderDetails',
    name: 'TenderDetails',
    component: () => import('./components/TenderDetails.vue'),
    meta: {
      name: '竞标详情'
    }
  },
  {
    path: '/home/ArticleContainer',
    name: 'ArticleContainer',
    component: () => import('./components/articleContainer.vue'),
    meta: {
      name: '公告集合'
    }
  },
  {
    path: '/home/ArticleDetail',
    name: 'home-articleDetail',
    component: () => import('./components/articleDetail.vue'),
    meta: {
      name: '文章详情'
    }
  }
];

export default routes;

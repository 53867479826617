export default {
    inserted(el, binding, vnode) {
        if (localStorage.getItem("userPermission") !== 'undefined' && localStorage.getItem("userPermission") !== null) {
            let userPermission = JSON.parse(localStorage.getItem("userPermission")),
                i = 0;

            if (binding.value.either) {
                for (; i < binding.value.value.length; i++) {
                    if (userPermission.indexOf(binding.value.value[i]) == '-1') {
                        return el.parentNode.removeChild(el)
                    }
                }
            } else {
                for (; i < binding.value.value.length; i++) {
                    if (userPermission.indexOf(binding.value.value[i]) !== '-1') {
                        return
                    }
                }
                return el.parentNode.removeChild(el)
            }
            // for(i<binding.value.length;i++){

            // }
            // if(userPermission.indexOf(binding.value) == '-1'){
            //     el.parentNode.removeChild(el)
            // }
        } else {
            el.parentNode.removeChild(el)
        }
    }
}
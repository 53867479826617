import fa from 'element-ui/src/locale/lang/fa';

export const API_PREFIX = process.env.VUE_APP_prefix;
export const financeSystemUrl = location.origin + '/credit-center-web'; // 融资系统地址
//1.配置接口请求链接
export default {
  // repeat 同时发送多个请求 show 开启遮罩层 splicing 是否需将参数拼接在URL上
  login: {
    // 登陆
    login: {url: 'wxtlogin/user/login', type: 'post', repeat: true, show: false, splicing: false},
    //校验登录名
    verifyLoginName: {url: 'wxtlogin/user/selectCountByLoginName', type: 'post', repeat: false, show: false, splicing: false},
    //验证手机号是否被使用
    selectCountByTel: {url: 'wxtlogin/user/selectCountByTel', type: 'post', repeat: false, show: false, splicing: false},
    //获取验证码
    getCode: {url: 'wxtlogin/user/code', type: 'post', repeat: false, show: false, splicing: false},
    //查看主账号拥有的权限
    selectPermiss: {url: 'wxtlogin/user/selectPermiss', type: 'post', repeat: false, show: false, splicing: false},
    //修改角色，权限
    updatePermission: {url: 'wxtlogin/user/updatePermission', type: 'post', repeat: false, show: false, splicing: false},
    //校验验证码
    verifyCode: {url: 'wxtlogin/user/verifyCode', type: 'post', repeat: false, show: false, splicing: false},
    //注册
    //
    register: {url: 'wxtlogin/user/register', type: 'post', repeat: false, show: false, splicing: false},
    //获取图形验证码
    getImgCode: {url: 'admin/auth/captchaImage', type: 'get', repeat: false, show: false, splicing: false},
    //根据手机号码修改密码
    updatePwdByTel: {url: 'wxtlogin/user/updatePwdByTel', type: 'post', repeat: false, show: false, splicing: false},
    loginOut: {url: 'wxtlogin/user/loginOut', type: 'get', repeat: false, show: false, splicing: false},
    // 修改密码
    updatePwd: {url: 'wxtlogin/user/updatePwd', type: 'post', repeat: false, show: false, splicing: false},
    // 绑定手机号码
    updateTel: {url: 'wxtlogin/user/updateTel', type: 'post', repeat: false, show: false, splicing: false},
    // 查询手机号和UKey是否绑定
    validateUKey: {url: 'wxtlogin/user/validateUKey', type: 'get', repeat: false, show: false, splicing: false},

    //根据手机号码修改密码
    updatePwdByTelNew: {url: 'wxtlogin/user/updatePwdByTelNew', type: 'post', repeat: false, show: false, splicing: false},

    // 设备地图的登陆
    deviceLogin: {url: 'admin/auth/login', type: 'post', repeat: false, show: false, splicing: false}
  },
  goodsInfo: {
    //轮播图下面的数据统计
    transactionDataStatistics: {url: 'wx/reqorder/orderSummed', type: 'get', repeat: true, show: false, splicing: false},
    //获取成交公告列表
    getLastMonthsInfo: {url: 'wx/noticeInfo/getLastMonthsInfo', type: 'get', repeat: true, show: false, splicing: false},
    //获取新闻详情
    getNoticeById: {url: 'wx/noticeInfo/getNoticeById', type: 'get', repeat: true, show: false, splicing: false},
    //竞标数据
    biddingInfolistPage: {url: 'wx/bidding/biddingInfolistPage', type: 'get', repeat: true, show: false, splicing: false},
    //获取系统公告和新闻动态
    showNoticeInfo: {url: 'wx/noticeInfo/showNoticeInfo', type: 'get', repeat: true, show: false, splicing: false},
    //成交公告
    showMsgPubInfo: {url: 'wx/noticeInfo/showMsgPubInfo', type: 'get', repeat: false, show: false, splicing: false},
    //推荐列表
    recommendList: {url: 'wx/recommend/recommendList', type: 'get', repeat: false, show: false, splicing: false},
    //所有商品列表
    goodsList: {url: 'wx/goods/goodList', type: 'post', repeat: false, show: false, splicing: false},
    //商品详情
    goodsDetailAndClient: {url: 'wx/goods/detailAndClient', type: 'get', repeat: true, show: false, splicing: false},
    // 热门竞买
    getHotProduct: {url: 'wx/product/hotProduct', type: 'get', repeat: true, show: false, splicing: false},
    hotReqProduct: {url: 'wx/product/hotReqProduct', type: 'get', repeat: true, show: false, splicing: false},
    //企业商品列表
    getClientGoods: {url: 'wx/goods/clientGoods', type: 'post', repeat: true, show: false, splicing: false},
    //商品添加
    publishGoods: {url: 'wx/goods/create', type: 'post', repeat: true, show: false, splicing: false},
    //企业商品详情
    goodsDetail: {url: 'wx/goods/goodsDetail', type: 'get', repeat: true, show: false, splicing: false},
    //企业商品修改
    modifyGoods: {url: 'wx/goods/update', type: 'post', repeat: true, show: false, splicing: false},
    //企业商品删除
    delGoods: {url: 'wx/goods/delete', type: 'get', repeat: true, show: true, splicing: false},
    //根据供应商id查询商品信息
    selectByClientId: {url: 'wx/goods/selectByClientId', type: 'post', repeat: true, show: false, splicing: false},
    //判断商品是否是自家的
    isMyGoods: {url: 'wx/goods/isMyGoods', type: 'get', repeat: true, show: false, splicing: false},
    //商品审核
    goodCheck: {url: 'wx/goods/goodCheck', type: 'post', repeat: true, show: false, splicing: false},
    //首页轮播图
    rotationImgList: {url: 'wx/rotationImg/list', type: 'get', repeat: true, show: false, splicing: false}
  },
  category: {
    categoryAll: {url: 'wx/catalog/categoryAll', type: 'get', repeat: true, show: false, splicing: true}
  },
  proProduct: {
    // 竞买中心列表
    proProductList: {url: 'wx/product/reqProducList', type: 'post', repeat: true, show: false, splicing: false},
    // 竞买审核
    auditOrFaudit: {url: 'wx/product/audit', type: 'get', repeat: true, show: false, splicing: false},
    // 新的竞买中心列表
    newProProductList: {url: 'wx/product/reqProducByBath', type: 'post', repeat: true, show: false, splicing: false},
    // 竞买中心报价成功
    getQuteSuccessList: {url: 'wx/product/getBatchPriceSucc', type: 'get', repeat: true, show: false, splicing: false},
    // 竞买中心历史批次
    getProductHistoryList: {url: 'wx/product/getProductHistory', type: 'get', repeat: true, show: false, splicing: false},
    // 参与的竞买
    getMyPartProduct: {url: 'wx/product/getMyPartProduct', type: 'post', repeat: true, show: false, splicing: false},
    // 新增竞买
    addProProduct: {url: 'wx/reProduct/saveReqProduct', type: 'post', repeat: true, show: false, splicing: false},
    // 修改竞买
    updateProProduct: {url: 'wx/reProduct/updateReqProductInfo', type: 'post', repeat: true, show: false, splicing: false},
    // 撤回竞买
    withdrawProduct: {url: 'wx/product/unAudit', type: 'get', repeat: true, show: false, splicing: false},
    // 删除竞买
    deleteProProduct: {url: 'wx/product/deleteProduct', type: 'post', repeat: true, show: false, splicing: false},
    // 新增竞买报价
    addSingleProductQuote: {url: 'wx/proprice/addSinglePrice', type: 'post', repeat: true, show: false, splicing: false},
    // 新增竞买报价
    addProductQuote: {url: 'wx/proprice/addPrice', type: 'post', repeat: true, show: false, splicing: false},
    // 获取竞买详情
    getProducDetail: {url: 'wx/reProduct/getReqProducDetail', type: 'get', repeat: true, show: false, splicing: false},
    //查询已结束的竞买订单和查看人是否为同一公司
    isMyOrder: {url: 'wx/reqorder/isMyOrder', type: 'post', repeat: true, show: false, splicing: false},
    // 获取报价列表
    getPriceList: {url: 'wx/proprice/list', type: 'get', repeat: true, show: false, splicing: false},
    // 确认采购
    confirmPurchase: {url: 'wx/proprice/makeBargain', type: 'get', repeat: true, show: false, splicing: false},
    // 报价订单信息
    getProPriceOrderInfo: {url: 'wx/proprice/getProPriceOrderInfo', type: 'get', repeat: true, show: false, splicing: false},
    // 竞买订单创建
    createReqOrder: {url: 'wx/reqorder/create', type: 'post', repeat: true, show: false, splicing: false},
    // 订单详情
    detailOrder: {url: 'wx/reqorder/orderDeatail', type: 'get', repeat: true, show: false, splicing: false},
    // 买家竞买/订单列表
    buyderOrderList: {url: 'wx/reqorder/buyderOrderList', type: 'post', repeat: true, show: false, splicing: false},
    // 賣家竞买/订单列表
    sellderOrderList: {url: 'wx/reqorder/supplierOrderList', type: 'post', repeat: true, show: false, splicing: false},
    // 获取当前最低价
    getCurrentMinPrice: {url: 'wx/proprice/checkMinPrice', type: 'get', repeat: true, show: false, splicing: false},
    // 新增竞标
    addCompetitive: {url: 'wx/biddingOrder/saveBiddingInfo', type: 'post', repeat: true, show: false, splicing: false},
    // 获取我的竞标
    getMyCompetitive: {url: 'wx/bidding/biddingList', type: 'post', repeat: true, show: false, splicing: false},
    // 获取所有合同
    // getAllContract: { url: "wx/contract/selectAll", type: 'get', repeat: true, show: false, splicing: false },
    getBIContract: {url: 'wx/contract/selectBI', type: 'get', repeat: true, show: false, splicing: false},
    getREContract: {url: 'wx/contract/selectRE', type: 'get', repeat: true, show: false, splicing: false},
    getAUContract: {url: 'wx/contract/selectAU', type: 'get', repeat: true, show: false, splicing: false},
    getContractInfo: {url: 'wx/contract/selectInfo', type: 'get', repeat: true, show: false, splicing: false},
    // 获取参与的竞标
    getParticipateCompetitive: {url: 'wx/TbidPrices/participation', type: 'post', repeat: true, show: false, splicing: false},
    // 修改竞标
    updateCompetitive: {url: 'wx/biddingOrder/updateBiddingInfo', type: 'post', repeat: true, show: false, splicing: false},
    // 撤回竞标
    withdrawCompetitive: {url: 'wx/bidding/recall', type: 'get', repeat: true, show: false, splicing: false}
  },
  users: {
    //是否可以添加子账户
    isAuthority: {url: 'wxtlogin/user/isAuthority', type: 'post', repeat: false, show: false, splicing: false},
    //获取权限
    myAuthority: {url: 'wxtlogin/user/isPermissions', type: 'post', repeat: false, show: false, splicing: false},
    //获取用户中心卖家任务信息
    mysellerOrderList: {url: '/wx/orderManagement/sellerOrderList', type: 'post', repeat: false, show: false, splicing: false},
    //获取用户中心买家任务信息
    mybuyderOrderList: {url: '/wx/orderManagement/buyderOrderList', type: 'post', repeat: false, show: false, splicing: false},
    //获取用户中心订单信息
    static4OrderAmt: {url: 'wx/amtStatic/static4OrderAmt', type: 'get', repeat: true, show: false, splicing: false},
    //添加子账号
    registerBypass: {url: '/wxtlogin/user/registerBypass', type: 'post', repeat: false, show: false, splicing: false},
    //根据用户名查询账号类型
    selectAccountTypeByLoginName: {url: '/wxtlogin/user/selectAccountTypeByLoginName', type: 'post', repeat: true, show: false, splicing: false},
    //查询子账户
    selectSubInfo: {url: '/wxtlogin/user/selectSubInfo', type: 'post', repeat: true, show: false, splicing: false},
    //启用/禁用子账户
    disableByLoginName: {url: '/wxtlogin/user/disableByLoginName', type: 'post', repeat: true, show: false, splicing: false},
    // 修改子账户信息
    updateSubInfoByLoginName: {url: 'wxtlogin/user/updateSubInfoByLoginName', type: 'post', repeat: false, show: false, splicing: false},
    //根据id物理删除
    deleteById: {url: 'wxtlogin/user/deleteById', type: 'post', repeat: false, show: false, splicing: false},
    //修改用户头像或者用户名
    updateUserNameOrPhotoUrl: {url: '/wxtlogin/user/updateUserNameOrPhotoUrl', type: 'get', repeat: false, show: false, splicing: false}, //修改用户头像或者用户名
    findById: {url: 'wxtlogin/user/findById', type: 'post', repeat: false, show: false, splicing: false},
    // 站内消息列表
    getMessgeList: {url: 'wx/tmsginfo/selectByOption', type: 'get', repeat: false, show: false, splicing: false},
    // 更改状态标识
    updateReadStatus: {url: 'wx/tmsginfo/updateReadStatus', type: 'post', repeat: false, show: false, splicing: false},
    // 全部消息已读
    readAllMessage: {url: 'wx/tmsginfo/readAll', type: 'post', repeat: false, show: false, splicing: false},
    // 收付款账号详情
    getCollectPayInfo: {url: 'wx/collectPayInfo/showCollectPayInfo', type: 'get', repeat: false, show: false, splicing: false},
    // 获取收款账号
    collectPayInfoList: {url: 'wx/collectPayInfo/list', type: 'get', repeat: false, show: false, splicing: false},
    // 修改合同信息
    updateCollectPayId: {url: 'wx/tcontractinfo/updateCollectPayId', type: 'post', repeat: false, show: false, splicing: false},
    // 收付款账号修改
    updatePayInfo: {url: 'wx/collectPayInfo/updatePayInfo', type: 'post', repeat: false, show: false, splicing: false},
    // 收付款账号新增
    insertPayInfo: {url: 'wx/collectPayInfo/insertPayInfo', type: 'post', repeat: false, show: false, splicing: false},
    // 添加收付款账号
    addCollectPayInfo: {url: 'wx/collectPayInfo/addAndModify', type: 'post', repeat: false, show: false, splicing: false}
  },
  clientInfo: {
    //企业资料认证
    updateTClientInfoOne: {url: 'wx/clientInfo/updateTClientInfoOne', type: 'post', repeat: true, show: false, splicing: false},
    //法人资料认证
    updateTClientInfoTwo: {url: 'wx/clientInfo/updateTClientInfoTwo', type: 'post', repeat: true, show: false, splicing: false},
    //企业信息
    findByClientInfoId: {url: 'wx/clientInfo/findByClientInfoId', type: 'get', repeat: true, show: false, splicing: false},
    //根据用户id查询用户资料
    selectByPrimaryKey: {url: 'wxtlogin/user/selectByPrimaryKey', type: 'get', repeat: true, show: false, splicing: false},
    //审核被拒绝之后重新申请,保留原来的数据
    updateApprvStatus: {url: 'wx/clientInfo/updateApprvStatus', type: 'get', repeat: true, show: false, splicing: false},
    //根据企业id查询
    findClientInfoById: {url: 'wx/clientInfo/findClientInfoById', type: 'post', repeat: true, show: false, splicing: false},
    //认证书认证
    updateTClientInfoThree: {url: 'wx/clientInfo/updateTClientInfoThree', type: 'post', repeat: true, show: false, splicing: false},
    // 获取公司 logo
    getCompanyLogo: {url: 'wx/clientInfo/selectLogo', type: 'get', repeat: true, show: false, splicing: false},
    // 获取发票信息
    getInvoiceInfo: {url: 'wx/collectPayInfo/showInvoicingInfo', type: 'get', repeat: true, show: false, splicing: false},
    // 查询企业账号数
    queryEnterpriseAccount: {url: 'wxtlogin/user/countBYenterpriseId', type: 'get', repeat: true, show: false, splicing: false}
  },
  //意向 (购物车)
  cart: {
    //我的意向列表
    cartList: {url: 'wx/cart/index', type: 'get', repeat: true, show: true, splicing: false},
    //删除意向
    deleteCart: {url: 'wx/cart/deleteCart', type: 'post', repeat: true, show: false, splicing: false},
    //增加
    addCart: {url: 'wx/cart/add', type: 'post', repeat: false, show: false, splicing: false},
    //获取意向列表商品数
    getCartCount: {url: 'wx/cart/goodscount', type: 'get', repeat: true, show: false, splicing: false},
    //修改意向选中状态
    checkedCart: {url: 'wx/cart/checked', type: 'post', repeat: true, show: false, splicing: false}
  },
  //竞卖相关
  autionInfo: {
    //竞卖商品列表
    autionInfolist: {url: 'wx/autionInfo/autionInfolist', type: 'get', repeat: true, show: false, splicing: false},
    //查询所有一级分类
    categoryList: {url: 'wx/catalog/all', type: 'get', repeat: true, show: false, splicing: false},
    //查询竞卖详情
    autionInfoDteail: {url: 'wx/autionInfo/read', type: 'get', repeat: true, show: false, splicing: false},
    //出价
    selectIsAution: {url: 'wx/autionDeal/selectIsAution', type: 'post', repeat: true, show: false, splicing: false},
    //添加竞卖
    addsAutionInfo: {url: 'wx/auction/saveAuction', type: 'post', repeat: true, show: true, splicing: false},
    //根据id查询已经出价的信息
    selectIsAutionById: {url: '/wx/autionDeal/selectIsAutionById', type: 'post', repeat: true, show: false, splicing: false},
    //我的竞卖列表
    myAutionInfolist: {url: 'wx/autionInfo/myAutionInfolist', type: 'get', repeat: true, show: true, splicing: false},
    //我的竞卖记录查看详情
    myAutionInfoDetail: {url: 'wx/autionInfo/myAutionInfoDetail', type: 'get', repeat: true, show: true, splicing: false},
    //编辑我的竞卖商品
    myAutionInfoUpdate: {url: 'wx/auction/updateAuction', type: 'post', repeat: true, show: false, splicing: false},
    //竞卖撤回
    myAutionInfoWithdraw: {url: '/wx/autionInfo/update', type: 'post', repeat: true, show: false, splicing: false},
    //审核
    checkbid: {url: '/wx/autionInfo/checkbid', type: 'post', repeat: true, show: false, splicing: false},
    //我的拍卖信息回显
    autionInfoById: {url: 'wx/auction/getAuctionInfoById', type: 'get', repeat: true, show: true, splicing: false}, //我的拍卖信息回显
    //查看该公司的竞卖商品
    findByClientInfoId: {url: 'wx/autionInfo/findByClientInfoId', type: 'get', repeat: true, show: true, splicing: false},
    //查看我的参与的竞卖数据(可以查看未审核的)
    readAutionInfoById: {url: 'wx/autionInfo/readAutionInfoById', type: 'get', repeat: true, show: true, splicing: false},
    //这个是竞买模板
    contractTextModel: {url: 'wx/autionDeal/contractTextModel', type: 'get', repeat: true, show: true, splicing: false},
    //这个才是真正的查找模板
    showContractMode: {url: 'wx/contracText/showContractMode', type: 'get', repeat: true, show: true, splicing: false},
    //我的拍卖信息回显
    //竞卖审核历史记录
    AUAuditList: {url: 'wx/auction/AUAuditList', type: 'post', repeat: true, show: false, splicing: false}
  }, //店铺相关
  brand: {
    //根据id查询店铺信息
    selectBrandById: {url: 'wx/brand/selectBrandByPrimaryKey', type: 'post', repeat: true, show: false, splicing: false},
    //申请店铺
    applyBrand: {url: 'wx/brand/applyBrand', type: 'post', repeat: true, show: false, splicing: false},
    //根据企业id查询店铺信息
    selectDtsBrandByCommpany: {url: 'wx/brand/selectDtsBrandByCommpany', type: 'post', repeat: true, show: false, splicing: false}
  },
  //热门相关
  hotAution: {
    //查询热门商品
    hotAutionInfolist: {url: 'wx/hotAution/hotAutionInfolistPage', type: 'get', repeat: true, show: false, splicing: false}
  },
  //订单
  order: {
    createOrder: {url: 'wx/order/createOrder', type: 'post', repeat: true, show: true, splicing: false},
    submitOrder: {url: 'wx/order/submitOrder', type: 'post', repeat: true, show: true, splicing: false},
    detailOrder: {url: 'wx/order/detailOrder', type: 'get', repeat: true, show: true, splicing: false},
    //支付凭证信息回显
    getZFEchoInformation: {url: 'wx/reqorder/getZFEchoInformation', type: 'post', repeat: true, show: false, splicing: false},
    //发票信息回显
    getKPEchoInformation: {url: 'wx/reqorder/getKPEchoInformation', type: 'post', repeat: true, show: false, splicing: false},
    //签收凭证信息回显
    getQSEchoInformation: {url: 'wx/reqorder/getQSEchoInformation', type: 'post', repeat: true, show: false, splicing: false},
    //发货信息回显
    getFHEchoInformation: {url: 'wx/reqorder/getFHEchoInformation', type: 'post', repeat: true, show: false, splicing: false},
    //获取分期付款/分期交货信息
    getInstallmentData: {url: 'wx/reqorder/getAdditionPayAndDeliverRateList', type: 'post', repeat: true, show: false, splicing: false},
    //审核通过
    passAudit: {url: 'wx/auditProcess/passAudit', type: 'post', repeat: true, show: false, splicing: false},
    //审核不通过
    rejectAudit: {url: 'wx/auditProcess/rejectAudit', type: 'post', repeat: true, show: false, splicing: false},
    //审核记录
    getAuditProcessList: {url: 'wx/auditProcess/list', type: 'post', repeat: true, show: false, splicing: false},
    //获取竞标的商品信息
    getTBidProductListData: {url: 'wx/reqorder/TBidProductList', type: 'post', repeat: true, show: false, splicing: false},
    //获取操作记录
    getOrderRecordList: {url: 'wx/record/getOrderRecordList', type: 'post', repeat: true, show: false, splicing: false},
    //直接购买
    buyNowGoods: {url: 'wx/order/buyNowGoods', type: 'post', repeat: true, show: true, splicing: false},
    //直接购买订单提交
    submitBuyNow: {url: 'wx/order/submitBuyNow', type: 'post', repeat: true, show: true, splicing: false},

    //用户订单
    listOrder: {url: 'wx/order/listOrder', type: 'get', repeat: true, show: true, splicing: false},
    //供应商订单
    sellderListOrder: {url: 'wx/order/sellderListOrder', type: 'get', repeat: true, show: true, splicing: false},
    //取消订单
    cancelOrder: {url: 'wx/order/cancelOrder', type: 'get', repeat: true, show: true, splicing: false},
    confirmCancelOrder: {url: 'wx/order/confirmCancelOrder', type: 'get', repeat: true, show: true, splicing: false},
    //订单记录
    getOperatingRecord: {url: 'wx/record/list', type: 'get', repeat: true, show: true, splicing: false},
    //订单交易操作
    oprOrderStatus: {url: 'wx/reqorder/oprOrderStatus', type: 'post', repeat: true, show: true, splicing: false},
    //订单状态修改
    changeOrderStatus: {url: 'wx/order/changeOrderStatus', type: 'post', repeat: true, show: true, splicing: false},
    // 获取我的合同
    getMyContranct: {url: 'wx/contractSignInfo/myContractSignInfo', type: 'get', repeat: true, show: true, splicing: false},
    // 合同详情
    getContranctDetail: {url: 'wx/contract/findById', type: 'post', repeat: true, show: true, splicing: false},
    // 合同签署
    // signContranct: {url: 'wx/contractSignInfo/creatPdf', type: 'post', repeat: true, show: true, splicing: false},
    // 获取合同哈希值
    getContractHash: {url: 'wx/contractSignInfo/creatPdfHash', type: 'post', repeat: true, show: true, splicing: false},
    // 合同签署
    signContranct: {url: 'wx/contractSignInfo/finishSign', type: 'post', repeat: true, show: true, splicing: false},
    // 竞标订单详情
    getBidOrderDetail: {url: 'wx/reqorder/biOrderDeatail', type: 'get', repeat: true, show: true, splicing: false},
    // 买家竞标订单列表
    getBuyderBidOrderList: {url: 'wx/reqorder/buyderBiOrderList', type: 'post', repeat: true, show: true, splicing: false},
    // 供应商竞标订单列表
    getSupplierBidOrderList: {url: 'wx/reqorder/supplierBiOrderList', type: 'post', repeat: true, show: true, splicing: false}
  },
  supplier: {
    apply: {url: 'wx/supplier/apply', type: 'get', repeat: true, show: false, splicing: false},
    checkSupplier: {url: 'wx/supplier/checkSupplier', type: 'get', repeat: true, show: false, splicing: false}
  },
  //我的竞卖记录
  autionDeal: {
    //查找个人的竞卖记录
    myAutionDealPage: {url: 'wx/autionDeal/myAutionDealPage', type: 'get', repeat: true, show: true, splicing: false},
    //完善订单获取供应商和买家信息
    autionDetail: {url: 'wx/autionDeal/detail', type: 'get', repeat: true, show: true, splicing: false}
  },
  //文件上传
  storage: {
    createStorage: {url: 'wx/storage/upload', type: 'post', repeat: true, show: false, splicing: false},
    identifyNote: {url: 'wx/chooseFile/identifyNote', type: 'get', repeat: true, show: false, splicing: false},
    vatInvoiceDistinguish: {url: 'wx/vatInvoiceFile/vatInvoice', type: 'get', repeat: true, show: false, splicing: false}
  },
  //收货地址
  address: {
    //得到用户地址
    userAddressList: {url: 'wx/address/list', type: 'get', repeat: true, show: true, splicing: false},
    //删除用户地址
    deleteAddress: {url: 'wx/address/delete', type: 'post', repeat: true, show: false, splicing: false},
    //地址详情
    detailAddress: {url: 'wx/address/detail', type: 'get', repeat: true, show: false, splicing: false},
    //地址修改或增加
    saveAddress: {url: 'wx/address/save', type: 'post', repeat: true, show: false, splicing: false}
  },
  //行政区域地址
  region: {
    //通过父级id
    regionList: {url: '/wx/region/list', type: 'get', repeat: true, show: false, splicing: false},
    //获取省市
    regionProvinceAndCity: {url: '/wx/region/regionList', type: 'get', repeat: true, show: false, splicing: false}
  },
  //证书管理
  certificate: {
    //查询我的证书
    tcredentialsigninfo: {url: 'wx/tcredentialsigninfo/selectByPageInfo', type: 'post', repeat: true, show: true, splicing: false},
    //证书使用记录
    tcredentialuseinfo: {url: '/wx/tcredentialuseinfo/selectByPageInfo', type: 'post', repeat: true, show: true, splicing: false}
  },
  //合作银行
  bankInfo: {
    //查询所有合作银行
    bankInfoAll: {url: '/wx/bankInfo/bankInfoAll', type: 'get', repeat: true, show: false, splicing: false}
  },
  //竞标
  tender: {
    //竞标中心列表
    getSelectBiddingList: {url: 'wx/bidding/selectBidding', type: 'post', repeat: true, show: true, splicing: false},
    //竞标详情
    getSelectBiddingDetails: {url: 'wx/biddingOrder/biddingInfoDetails', type: 'get', repeat: true, show: true, splicing: false},
    //提交竞标
    tbidPricesPrices: {url: 'wx/TbidPrices/prices', type: 'post', repeat: true, show: false, splicing: false},
    // 已报价企业
    getBiddingProClis: {url: 'wx/bidding/proClis', type: 'get', repeat: true, show: false, splicing: false},
    //我参与的竞标是否中标
    whetherBided: {url: 'wx/TbidPrices/whetherBided', type: 'post', repeat: true, show: false, splicing: false},
    //修改竞标的状态
    upstart: {url: 'wx/TbidPrices/upBidding', type: 'post', repeat: true, show: false, splicing: false},
    importExcel: API_PREFIX + '/wx/tbidproduct/importExcel',
    uploadPath: API_PREFIX + '/wx/storage/upload',
    //竞标审核历史记录
    BIAuditList: {url: 'wx/biddingOrder/BIAuditList', type: 'post', repeat: true, show: false, splicing: false},
    //竞买审核历史记录
    REAuditList: {url: 'wx/reqorder/REAuditList', type: 'post', repeat: true, show: false, splicing: false}
  },
  //南海灵通铝锭
  lvDingPrice: {
    //最新价格
    newestLvdingPrice: {url: 'wx/dingPrice/newestLvdingPrice', type: 'get', repeat: true, show: false, splicing: false}
  },
  uploadPath: API_PREFIX + '/wx/storage/upload',

  uploadPaths: API_PREFIX + '/wx/storage/uploads',

  // 企业设备
  equipment: {
    //获取设备在线数
    messageAll: {url: 'EobMessage/messageAll', type: 'get', repeat: false, show: true, splicing: false},

    //获取标识注册数
    idNumber: {url: 'EobMessage/IdNumber', type: 'get', repeat: false, show: true, splicing: false},

    //获取产品解析量
    countProduct: {url: 'admin/trackerContract/countProduct', type: 'get', repeat: false, show: true, splicing: false},

    //获取标识解析数
    idQuery: {url: 'EobMessage/IdQuery', type: 'get', repeat: false, show: true, splicing: false},

    //获取地图标记
    trackers: {url: 'EobMessage/trackers', type: 'get', repeat: false, show: true, splicing: false},

    //获取地图标记点弹窗详情
    getTrackerById: {url: 'admin/trackerContract/getTrackerById', type: 'get', repeat: false, show: true, splicing: false},

    // 设备合同列表
    getEquipmentList: {url: 'wx/trackerContract/list', type: 'post', repeat: false, show: true, splicing: false},

    // 获取项目详情
    selectContractById: {url: 'admin/trackerContract/selectContractById', type: 'get', repeat: false, show: true, splicing: false},

    // 获取项目详情图片
    getRelateImgById: {url: 'admin/trackerContract/getRelateImgById', type: 'get', repeat: false, show: true, splicing: false},

    // 查看设备合同详情
    checkEquipment: {url: 'wx/trackerContract/selectContractById', type: 'get', repeat: false, show: true, splicing: false},

    // 更新设备合同
    updateEquipment: {url: 'wx/trackerContract/update', type: 'post', repeat: false, show: true, splicing: false},

    // 新增设备合同
    addEquipment: {url: 'wx/trackerContract/save', type: 'post', repeat: false, show: true, splicing: false},

    // 可关联设备
    associateEquipment: {url: 'wx/trackerContract/getTrackerList', type: 'post', repeat: false, show: false, splicing: false},

    // 已关联设备(分页获取)
    getAllTrackerByUserId: {url: 'admin/trackerContract/getTrackByContractId', type: 'post', repeat: false, show: false, splicing: false},

    // 铝模板溯源列表（分页）
    getTraceabilityByUserId: {url: 'bigdata/queryPageMaterials', type: 'get', repeat: false, show: false, splicing: false},

    // 铝模板溯源楼栋下拉选项
    getQueryBuildingInfoByContractOn: {url: 'bigdata/queryBuildingInfoByContractOn', type: 'get', repeat: false, show: false, splicing: false},

    // 铝模板溯源详情页
    getTraceabilityDetail: {url: 'bigdata/queryNodeInfo', type: 'get', repeat: false, show: false, splicing: false},

    // 已关联设备（全部）
    getTrackListByContractId: {url: 'wx/trackerContract/getTrackListByContractId', type: 'post', repeat: false, show: false, splicing: false},

    // 关联设备
    batchInsertContractLink: {url: 'wx/trackerContract/batchInsertContractLink', type: 'post', repeat: false, show: false, splicing: false},

    // 删除设备合同
    delById: {url: 'wx/trackerContract/delById', type: 'get', repeat: false, show: false, splicing: false},

    // 获取移动中 。在线 、离线的设备
    countTrackerStatusByUserId: {url: 'admin/trackerContract/countTrackerStatusByUserId', type: 'get', repeat: false, show: false, splicing: false},

    // 获取项目列表
    cloudList: {url: 'admin/trackerContract/cloudList', type: 'post', repeat: false, show: false, splicing: false},

    // 从后管预览跳转到设备根据id获取移动中 。在线 、离线的设备
    countTrackerStatusByUserIdSkip: {url: 'wx/trackerContract/countTrackerStatusByUserIdSkip', type: 'get', repeat: false, show: false, splicing: false},

    // 地图-获取设备合同列表
    getContractByUseId: {url: 'wx/trackerContract/getContractByUseId', type: 'post', repeat: false, show: false, splicing: false},

    // 获取用户下所有的设备合同
    getContractAndTrackerByUserId: {url: 'wx/trackerContract/getContractAndTrackerByUserId', type: 'post', repeat: false, show: false, splicing: false},

    // 获取用户下所有的设备合同里的设备
    getAllTrackerVoByUserIdWithNoPageNoQuery: {url: 'admin/trackerContract/getAllTrackerVoByUserIdWithNoPageNoQuery', type: 'get', repeat: false, show: false, splicing: false},

    // 从后管预览跳转到设备根据id获取用户下所有的设备合同
    getContractAndTrackerByUserIdSkip: {url: 'wx/trackerContract/getContractAndTrackerByUserIdSkip', type: 'post', repeat: false, show: false, splicing: false},

    // 从后管预览调整到设备根据id获取用户下所有的设备合同里的设备
    getAllTrackerVoByUserIdWithNoPageNoQueryId: {url: 'wx/trackerContract/getAllTrackerVoByUserIdWithNoPageNoQueryId', type: 'get', repeat: false, show: false, splicing: false},

    //  获取所有的市
    getCity: {url: 'wx/EobMessage/shi', type: 'get', repeat: false, show: false, splicing: false},

    //  获取所有的县/区
    getCounty: {url: 'wx/EobMessage/xian', type: 'get', repeat: false, show: false, splicing: false}
  },
  // 子账号管理
  account_management: {
    //  获取所有该子账号的角色和角色权限
    permissionByAllRole: {url: 'wxtlogin/user/permissionByAllRole', type: 'post', repeat: false, show: false, splicing: false},
    //  获取子账号的所有权限
    permissionByParent: {url: 'wxtlogin/user/permissionByParent', type: 'post', repeat: false, show: false, splicing: false}
    //  获取所有的县/区
    // getCounty: {url: 'wx/EobMessage/xian', type: 'get', repeat: false, show: false, splicing: false}
  },
  // 帮助中心
  help: {
    // 帮助中心标题（二级菜单）
    helpdoc: {url: 'wx/helpdoc/pageList', type: 'get', repeat: true, show: false, splicing: false},
    // 文章
    selectOne: {url: 'wx/helpdoc/selectOne', type: 'post', repeat: true, show: false, splicing: false}
  },


  // 新增的接口
  other: {
    // 物质交付图片查询 /contract/getByTrackerContractId
    contractInfo: {url: 'jshERP-boot/contract/getByTrackerContractId', type: 'get', repeat: true, show: false, splicing: false},

    // 朔源查询
    contractEntityQuery: {url: 'jshERP-boot/depotHead/contractEntityQuery', type: 'get', repeat: true, show: false, splicing: false},

    // 首页的 产品信息注册量
    dataScreenSummary: {url: 'jshERP-boot/neo/dataScreenSummary', type: 'get', repeat: true, show: false, splicing: false},
  }
};

import axios from 'axios';

function Server(config) {
  this.config = _isPlainObject(config) ? Object.assign({}, config) : {};
  this.loadingComponent = Server.loadingComponent;
  const self = this;

  Server.prototype.loading = {
    showState: 'ready',
    load: null,
    pendingNum: 0,
    show(config) {
      if (!self.loadingComponent) {
        console.error('还未绑定加载组件');
        return;
      }
      this.pendingNum++;
      if (this.showState === 'pending') return;
      this.showState = 'pending';
      if (_isPlainObject(this.config)) {
        config = Object.assign({}, this.config, config);
      }
      this.load = self.loadingComponent.show(config);
    },

    hide() {
      if (!this.load) return;
      this.pendingNum--;
      if (this.pendingNum > 0) return;
      this.load.hide();
      this.load = null;
      this.showState = 'ready';
    }
  };

  return this.init(config);
}

Server.prototype.init = function(config) {
  axios.defaults.withCredentials = true;
  this.server = axios.create(config);
  this.interceptors = this.server.interceptors;
};
Server.prototype.parseRouter = function(...rest) {
  //   console.log(rest);
  var ob = null,
    urlOb = null,
    moduleName = null;
  if (typeof rest[0] === 'string') {
    moduleName = rest[0];
    ob = this[moduleName] = {};
    urlOb = _isPlainObject(rest[1]) ? rest[1] : {};
  } else if (_isPlainObject(rest[0])) {
    urlOb = rest[0];
    ob = this;
  } else {
    console.error('函数 parseRouter 接收的参数不符合预期');
    return;
  }

  Object.keys(urlOb).forEach(apiName => {
    ob[apiName] = this.sendMes.bind(this, moduleName, apiName, urlOb[apiName]);
    ob[apiName].state = 'ready';
  });
};

Server.prototype.sendMes = function(modelName, apiName, defaultConfig, data = {}, config = {}) {
  var type = defaultConfig.type || 'get';
  var url = defaultConfig.url + '?t=' + Date.now() || '';
  var api = modelName ? this[modelName][apiName] : this[apiName];

  var before = function(res) {
    api.state = 'ready';
    return res;
  };

  var beforeError = function(err) {
    api.state = 'ready';
    return Promise.reject(err);
  };

  if (api.state == 'ready' || defaultConfig.repeat) {
    if (defaultConfig.splicing && data) {
      url = url + '/' + data.splicing;
      delete data.splicing;
    }
    if (defaultConfig.show) {
      config.show = true;
    }

    let resPromise = this.server[type](url, data, config).then(before, beforeError);
    api.state = 'waiting';
    return resPromise;
  }

  let nothingRes = {
    then() {
      return nothingRes;
    },
    catch() {}
  };
  return nothingRes;
};

Server.prototype.bindLoading = function(component) {
  if (typeof component !== 'object' || component === null) {
    console.error(`bindLoading 函数的入参不符合要求`);
    return;
  }
  this.loadingComponent = component;
};

function _isPlainObject(obj) {
  return Object.prototype.toString.call(obj) === '[object Object]';
}

export default Server;

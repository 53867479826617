
const routes = [{
    path: '/home/UserNotice',
    name: 'UserNotice',
    component: () => import('./UserNotice.vue'),
    meta: {
        name: ''
    }
},{
    path: '/home/PlatformRule',
    name: 'PlatformRule',
    component: () => import('./PlatformRule.vue'),
    meta: {
        name: ''
    }
},{
    path: '/home/SupplierNotice',
    name: 'SupplierNotice',
    component: () => import('./SupplierNotice.vue'),
    meta: {
        name: ''
    }
},]


export default routes
import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    geocodeData: [],
    searchItem: '',
    carCount: 0,
    menuType: '',
    buyerRules: false,
    electronicSignature: false
  },
  mutations: {
    setCarCount(state, count) {
      state.carCount = count;
    },
    changeGeocodeData(state, item) {
      state.geocodeData = item;
    },
    changeMenuType(state, type) {
      state.menuType = type;
    },
    changeSearchItem(state, item) {
      state.searchItem = item;
    },
    changeBuyerRules(state, type) {
      state.buyerRules = type;
    },
    changeElectronicSignature(state, type) {
      state.electronicSignature = type;
    }
  },
  getters: {
    getGeocodeData: state => {
      return state.geocodeData;
    },
    getterSearchItem: state => {
      return state.searchItem;
    },
    getterMenuType: state => {
      return state.menuType;
    },
    getterBuyerRules: state => {
      return state.buyerRules;
    },
    getterElectronicSignature: state => {
      return state.electronicSignature;
    }
  },
  actions: {},
  modules: {}
});

import server from '@/api'
// console.log('测试')
// server.login.loginIn()

export function loginOut (param) {
  return server.login.loginOut(param)
}

//获取购物车商品数量
export function getCartCount (params) {
  return server.cart.getCartCount({ params });
}

//品种
export function categoryAll (categoryId = null) {
  return server.category.categoryAll(categoryId);
}

//站内消息
export function getMessgeList (params) {
  return server.users.getMessgeList(params);
}

// 已读全部消息 
export function readAllMessage (params) {
  return server.users.readAllMessage(params);
}

//查询子账户
export function queryEnterpriseAccount () {
  return server.clientInfo.queryEnterpriseAccount()
}
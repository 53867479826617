import Vue from 'vue';
Vue.filter('formatPrice', function(val) {
  if (Number(val) <= 0 || !val) return '-';
  val = val.toString();
  if (val.indexOf('.') == -1) val = `${val}.00`;
  let myVal = [];
  let firestVal = val.substr(0, val.indexOf('.'));
  let lastVal = val.substr(-3);
  let alllength = Math.floor(firestVal.toString().length / 3);
  let headerLength = val.toString().length % 3;
  for (let i = 0; i < alllength; i++) {
    myVal.unshift(firestVal.toString().substr(i * -3 - 3, 3));
  }
  if (headerLength) myVal.unshift(val.toString().substr(0, headerLength));
  return myVal.join(',') + lastVal;
});


export default {
    //日期格式转换
    convertDate: function (date) {
        let d = new Date(date); //传入时间
        let month = d.getMonth() + 1 < 10 ? "0" + (d.getMonth() + 1) : d.getMonth() + 1;

        let day = d.getDate() < 10 ? "0" + d.getDate() : d.getDate();

        let hours = d.getHours() < 10 ? "0" + d.getHours() : d.getHours();

        let min = d.getMinutes() < 10 ? "0" + d.getMinutes() : d.getMinutes();

        let sec = d.getSeconds() < 10 ? "0" + d.getSeconds() : d.getSeconds();

        let times = d.getFullYear() + "-" + month + "-" + day + " " + hours + ":" + min + ":" + sec;

        return times;
    },
    //获取当前时间
    formatDate: function () {
        let date = new Date();
        let year = date.getFullYear(); // 年
        let month = (date.getMonth() + 1)<10?"0"+(date.getMonth() + 1):(date.getMonth() + 1); // 月
        let day = date.getDate()?"0"+date.getDate() :date.getDate(); // 日
        let week = date.getDay(); // 星期
        let weekArr = ["星期日", "星期一", "星期二", "星期三", "星期四", "星期五", "星期六"];
        let hour = date.getHours(); // 时
        hour = hour < 10 ? "0" + hour : hour; // 如果只有一位，则前面补零
        let minute = date.getMinutes(); // 分
        minute = minute < 10 ? "0" + minute : minute; // 如果只有一位，则前面补零
        let second = date.getSeconds(); // 秒
        second = second < 10 ? "0" + second : second; // 如果只有一位，则前面补零
        return `${year}:${month}:${day} ${hour}:${minute}:${second}`;

    },

    // 倒计时
    countdown: function(endTime) {
        const _this = this;
        const end = Date.parse(new Date(endTime));
        const now = Date.parse(new Date());
        const msec = end - now;
        if(msec <= 0) {
            clearTimeout(this._timeout);
        } else {
            let d = parseInt(msec / 1000 / 60 / 60 / 24);
            let h = parseInt(msec / 1000 / 60 / 60 % 24);
            let m = parseInt(msec / 1000 / 60 % 60);
            let s = parseInt(msec / 1000 % 60);
            this.day = d;
            this.hour = h > 9 ? h : '0' + h;
            this.minute = m > 9 ? m : '0' + m;
            this.second = s > 9 ? s : '0' + s;
            this._timeout = setTimeout(function () {
                _this.countdown(endTime);
            }, 1000);
        }
    },


}